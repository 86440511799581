.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  margin-left: $timeline-outline-size/2;
  margin-right: 2rem;


  .timeline-line {
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    top:0;
    padding-top: $timeline-outline-size;
    left:0;
    bottom:0;
    &:after {
      position: relative;
      display: block;
      height: 100%;
      border: 1px solid $gray-300;
      width: 1px;
      content: " ";
    }

  }

}
.timeline-event-card {
  padding: 1rem 1rem;

}
.timeline-event {

  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-top: 0;
  padding-bottom: 2rem;
  padding-left: 1.5rem;


  &:before {

  }
  &:after {

    position: absolute;
    left: 0;
    top:0;
    margin-left: -$timeline-dot-size/2;
    width: $timeline-dot-size;
    height: $timeline-dot-size;
    margin-top: ((($timeline-outline-size - $timeline-dot-size)/2));
    content: " ";
    border-radius: 50%;
    background: $info;
    z-index:2;


  }

  &:before {
    position: absolute;
    left: 0;
    top:0;
    margin-left: (-$timeline-outline-size/2);
    width: $timeline-outline-size;
    height: $timeline-outline-size;
    content: " ";
    border-radius: 50%;
    background: transparent;
    border: 2px solid $info;
    z-index:1;
    box-sizing: border-box;

  }
  &.single-dot {
    &:after {
      margin-top: 0;
      margin-left: -$timeline-outline-size/2;
      width: $timeline-outline-size;
      height: $timeline-outline-size;
    }

    &:before {
      display: none;

    }
  }
  &.single-circle {
    &:after {
      display: none;
    }
  }
}

.timeline-lg {
  .timeline-line {
    padding-top: $timeline-lg-outline-size;
  }
  .timeline-event {

    &:after {
      margin-left: -$timeline-lg-dot-size/2;
      width: $timeline-lg-dot-size;
      height: $timeline-lg-dot-size;
      margin-top: ((($timeline-lg-outline-size - $timeline-lg-dot-size)/2));
    }
    &:before {
      margin-left: (-$timeline-lg-outline-size/2);
      width: $timeline-lg-outline-size;
      height: $timeline-lg-outline-size;
    }
    &.single-dot {
      &:after {
        margin-left: -$timeline-lg-outline-size/2;
        width: $timeline-lg-outline-size;
        height: $timeline-lg-outline-size;
      }
    }

  }

}
@each $color, $value in $theme-colors {

  .timeline-event-#{$color} {
    &:after {
      background: $value;
    }
    &:before {
      border: 2px solid $value;
    }
  }

}

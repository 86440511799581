
.carousel-light {
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
  }
  .carousel-indicators li {
    background-color: $gray-400;
    &.active {
      background-color: $gray-600;;
    }
  }
}

.carousel-slim {
  .carousel-control-prev, .carousel-control-next {
    width: 2rem;
  }
  .carousel-indicators {
    margin-bottom: 0;
  }
}
.carousel-item, .carousel-inner, .carousel, .slide {
  outline: 0;
  &.active, &:active, &:focus {
    outline: 0;
  }
}

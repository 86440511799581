.fc-toolbar.fc-header-toolbar {
    padding: 1rem 1.5rem;
    padding-top: 6rem;
    margin-bottom: 0;
    button {
        background: $info;
        color: #fff;
        border: none;
        padding: 0.5rem 0.75rem;
        height: 2.8em;
        line-height: 1;
        border-radius: 0px;
        text-transform: capitalize;

        text-shadow: none;
        &:hover {
            background: darken($info,5%);
        }
    }
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    .fc-left,.fc-right {
      float: none;
    }
    .fc-left {
      order: 0;
    }
    .fc-center {
      order: 1;
    }
    .fc-right {
      order: 2;
    }
}
.fc-left {
    h2 {
        font-size: 1.5em;
    }
}
table {
    .fc-head,.fc-body {
        .fc-widget-header,.fc-widget-content {
            td,th {
                padding: 0.5rem 0.1rem;
            }
        }

    }
}
.fc-widget-content {
    .fc-day-grid {
        .fc-day-number {
            padding: 0.75rem;
        }
    }
}
.fc-month-view {
    >table,  {
        border-left:none !important;
        >thead,>tbody  {
            >tr {
                >td {
                    border-left:none !important;
                    border-right:none !important;
                }
            }
        }

    }
}
.fc-event-container {
  padding: 0 !important;
}
.external-events .fc-event {
    margin-bottom: 0.5em;
}
.fc-event,.fc-event-dot {
    border-radius: 0;
    padding: 0.25rem;
    background-color: $info;
    color: white !important;

}
.fc-scroller {
  height: auto !important;
}
@include media-breakpoint-down(sm) {
  body .fc {
    font-size: 0.9em !important;
    .fc-toolbar.fc-header-toolbar {
      position: relative;
        padding-top: 4rem;
        button {
            padding: 0.5rem 0.5rem;
            font-size: 0.9em !important;
        }
      }
  }
  .fc-left {
      h2 {
          font-size: 1.2em;
          font-weight: 500;
      }
  }
  .fc-right {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .fc-widget-content {
      .fc-day-grid {
          .fc-day-number {
              padding: 0 0.25rem;
              font-size:0.9em;
          }
      }
  }

}

.bg-gradient {

  background-color: #313446;
  color: white !important;
  background-image: url("../assets/img/bg-8.png");
  background-repeat: no-repeat;
  background-size: 116rem;
  background-position: top;
  //background-attachment: fixed;

}
.font-fancy {
  font-family: Cookie, cursive;
  font-size: 2rem !important;
}

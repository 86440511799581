.btn {
  cursor: pointer;
  font-size: 0.9rem;
  &.btn-sm {
    font-size: 0.8rem;
  }
  &.btn-lg {
    font-size: 1.1rem;
  }
  .material-icons {
    line-height: 1;
  }
  &:focus, &:active {
    box-shadow: none !important;
  }
  .fa {
    vertical-align: middle;
  }
  &.no-shadow {
    box-shadow: none !important;
  }

}
.btn-fab {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;
  padding: 0;
  line-height: 3.25rem;
  font-size: 1rem;


  &.btn-lg {
    width: 4.25rem;
    height: 4.25rem;
    line-height: 4.25rem;
    font-size: 1.25rem;
  }
  &.btn-sm {
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
    font-size: 0.9rem;
  }
  &.btn-xs {
    width: 1.5rem;
    height: 15rem;
    line-height: 1.5rem;
    font-size: 0.8rem;
  }
  &.fixed-bottom-right {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 5;
  }

}

.btn-icon {
  width: 3rem;
  height: 2.7rem;
  padding: 0;
  line-height: 2.6rem;
  &.btn-lg {
    width: 4.25rem;
    height: 4.25rem;
    line-height: 4.25rem;
    font-size: 1.25rem;
  }
  &.btn-sm {
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
    font-size: 0.9rem;
  }
  &.btn-xs {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.8rem;
  }
}
.btn-pill {
  border-radius: 10em;
}
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1);
    &:focus, &:active {
      box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1);
    }
  }
  .btn-flat-#{$color} {
    @include button-flat-variant($value);

    &:focus, &:active {
      box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1) !important;
    }
    &.no-shadow {
      &:focus {
        box-shadow: none !important;
      }
    }
  }

}

.btn-group {
  box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1);
  .btn {
    box-shadow: none !important;
  }
}

.dropdown.no-caret {
  >.dropdown-toggle {
    &::after {
      content: none;
      display: none;
    }
  }
}
.dropdown-item.active, .dropdown-item:active {
  background: $primary !important;
  color: white !important;
}


.list-group.list-group-sm {
  .list-group-item {
    padding: 0.3rem 0.75rem;
  }
}
.list-group.list-group-borderless {
  .list-group-item {
    border: none;
  }
}
.block-list {
  display: block;
  margin: 0;
  padding: 0;
  > li {
    display: block;
    margin: 0;
    padding: 0;
  }
}

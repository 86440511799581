/**
*
*  Simplify Admin
*  v1.0
*  Created by geekman.site
*
*  Last Modified: 31 December 2017
*  See Manual for Full Changelog
*
*/
/*
* Simplify Admin Modifies and extends Bootstrap.
*/
/****** Table Of Content *******
*. Functions
*. Variables
*. Mixins
*. Bootstrap Print Styles
*. Bootstrap Reboot
*. Images
*. Code
*. Grid
*. Tables
*. Forms and Custom Forms
*. Input Group
*. Buttons
*. Button Group
*. Transitions
*. Dropdown
*. Navigation and Tabs
*. Navbar
*. Card
*. Breadcrumbs
*. Pagination
*. Badges
*. Jumbotron
*. Alert
*. Progress
*. Media
*. List-group
*. Close
*. Modal
*. Tooltip
*. Popover
*. Carousel
*. Utilities
*. Icons
*. Layout
*. Sidebar
*. Topbar
*. Timeline


/* Bootstrap Functions */
/* Variables */
/* Mixins */
/* Bootstrap Root Styling and Variables */
:root {
  --blue: #3a92ff;
  --indigo: #6610f2;
  --purple: #8670f2;
  --pink: #e83e8c;
  --red: #eb4c5d;
  --orange: #fd780a;
  --yellow: #ffc411;
  --green: #1cbd35;
  --teal: #26c1b2;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #8670f2;
  --secondary: #868e96;
  --success: #1cbd35;
  --info: #3a92ff;
  --warning: #fd780a;
  --danger: #eb4c5d;
  --light: #f3f6fa;
  --dark: #525f6c;
  --teal: #26c1b2;
  --indigo: #6610f2;
  --cyan: #17a2b8;
  --yellow: #ffc411;
  --pink: #e83e8c;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "Roboto Mono", "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/*
Set the root font size to make the entire UI larger or smaller.
*/
:root {
  font-size: 14px;
  font-size: 14px;
}

@media (min-width: 576px) {
  :root {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  :root {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  :root {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  :root {
    font-size: 16px;
  }
}

/* Bootstrap Print Styling */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

/* Bootstrap Reboot */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #576472;
  text-align: left;
  background-color: #f3f6fa;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #3a92ff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #006aed;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* Typography */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 1.925rem;
}

h2, .h2 {
  font-size: 1.6625rem;
}

h3, .h3 {
  font-size: 1.4rem;
}

h4, .h4 {
  font-size: 1.1375rem;
}

h5, .h5 {
  font-size: 0.9625rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

h5, h6 {
  font-weight: 400;
}

h1.section-title, h2.section-title, h3.section-title, h4.section-title, h5.section-title, h6.section-title {
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin-bottom: 30px;
}

.text-sm {
  font-size: 0.95em !important;
}

.text-lg {
  font-size: 1.3em !important;
}

.text-xs {
  font-size: 0.95em !important;
}

.text-xl {
  font-size: 1.5em !important;
}

.text-tall {
  line-height: 2;
}

.text-xxl {
  font-size: 2em;
}

a:focus, a:active, .btn:focus, .btn:active {
  outline: none;
}

/* Images */
.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f3f6fa;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

/* Code */
code,
kbd,
pre,
samp {
  font-family: "Roboto Mono", "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #cb666b;
  background-color: #f3f6fa;
  border-radius: 2px;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 2px;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

/* Bootstrap Grid */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* Tables */
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e8ecf1;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8ecf1;
}

.table tbody + tbody {
  border-top: 2px solid #e8ecf1;
}

.table .table {
  background-color: #f3f6fa;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e8ecf1;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e8ecf1;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e8ecf1;
}

.table-hover tbody tr:hover {
  background-color: #f3f6fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ddd7fb;
}

.table-hover .table-primary:hover {
  background-color: #c9c0f9;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c9c0f9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bfedc6;
}

.table-hover .table-success:hover {
  background-color: #abe7b4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #abe7b4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8e0ff;
}

.table-hover .table-info:hover {
  background-color: #afd2ff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #afd2ff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fed9ba;
}

.table-hover .table-warning:hover {
  background-color: #fecba1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fecba1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9cdd2;
}

.table-hover .table-danger:hover {
  background-color: #f6b6be;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f6b6be;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfe;
}

.table-hover .table-light:hover {
  background-color: #e9e9f8;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e9e9f8;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cfd2d6;
}

.table-hover .table-dark:hover {
  background-color: #c1c5ca;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c1c5ca;
}

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #c2eee9;
}

.table-hover .table-teal:hover {
  background-color: #aee8e2;
}

.table-hover .table-teal:hover > td,
.table-hover .table-teal:hover > th {
  background-color: #aee8e2;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #d4bcfb;
}

.table-hover .table-indigo:hover {
  background-color: #c5a4fa;
}

.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #c5a4fa;
}

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #bee5eb;
}

.table-hover .table-cyan:hover {
  background-color: #abdde5;
}

.table-hover .table-cyan:hover > td,
.table-hover .table-cyan:hover > th {
  background-color: #abdde5;
}

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #ffeebc;
}

.table-hover .table-yellow:hover {
  background-color: #ffe8a3;
}

.table-hover .table-yellow:hover > td,
.table-hover .table-yellow:hover > th {
  background-color: #ffe8a3;
}

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #f9c9df;
}

.table-hover .table-pink:hover {
  background-color: #f6b2d1;
}

.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: #f6b2d1;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f3f6fa;
}

.table-hover .table-active:hover {
  background-color: #e1e9f3;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e1e9f3;
}

.table .thead-dark th {
  color: #f3f6fa;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #525f6c;
  background-color: #e8ecf1;
  border-color: #e8ecf1;
}

.table-dark {
  color: #f3f6fa;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm.table-bordered {
    border: 0;
  }
}

@media (max-width: 767px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md.table-bordered {
    border: 0;
  }
}

@media (max-width: 991px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
  border: 0;
}

.table thead tr td, .table thead tr th {
  border-bottom-width: 1px;
}

.table thead tr th, .table tbody tr th {
  font-weight: 500;
}

.table.table-middle tbody tr td, .table.table-middle tbody tr th {
  vertical-align: middle;
}

.table.table-compact thead tr td, .table.table-compact thead tr th, .table.table-compact tbody tr td, .table.table-compact tbody tr th, .table.table-compact tfoot tr td, .table.table-compact tfoot tr th {
  padding: 0.75rem 0.5rem;
}

.table-material thead tr td:first-child, .table-material thead tr th:first-child, .table-material tbody tr td:first-child, .table-material tbody tr th:first-child, .table-material tfoot tr td:first-child, .table-material tfoot tr th:first-child {
  padding-left: 2rem;
}

.table-material thead tr td:last-child, .table-material thead tr th:last-child, .table-material tbody tr td:last-child, .table-material tbody tr th:last-child, .table-material tfoot tr td:last-child, .table-material tfoot tr th:last-child {
  padding-right: 2rem;
}

@media (max-width: 575px) {
  .rt-xs thead tr, .rt-xs tbody tr {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e8ecf1;
  }
  .rt-xs thead tr td, .rt-xs thead tr th, .rt-xs tbody tr td, .rt-xs tbody tr th {
    border: none;
    padding: 0.75rem 0.75rem 0.75rem 1.5em;
  }
  .rt-xs thead tr td.rt-full, .rt-xs thead tr th.rt-full, .rt-xs tbody tr td.rt-full, .rt-xs tbody tr th.rt-full {
    flex-basis: 100%;
    flex-shrink: 0;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  }
  .rt-xs thead tr td.rt-header, .rt-xs thead tr th.rt-header, .rt-xs tbody tr td.rt-header, .rt-xs tbody tr th.rt-header {
    font-weight: 500;
  }
  .rt-xs thead tr td:first-child, .rt-xs thead tr th:first-child, .rt-xs tbody tr td:first-child, .rt-xs tbody tr th:first-child {
    padding-left: 1.5rem;
  }
  .rt-xs thead tr td:last-child, .rt-xs thead tr th:last-child, .rt-xs tbody tr td:last-child, .rt-xs tbody tr th:last-child {
    padding-right: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .rt-xs .rt-table-hide {
    display: none !important;
  }
  .rt-xs .rt-table-block {
    display: block !important;
  }
  .rt-xs .rt-table-inline {
    display: inline !important;
  }
  .rt-xs .progress {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .rt-sm thead tr, .rt-sm tbody tr {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e8ecf1;
  }
  .rt-sm thead tr td, .rt-sm thead tr th, .rt-sm tbody tr td, .rt-sm tbody tr th {
    border: none;
    padding: 0.75rem 0.75rem 0.75rem 1.5em;
  }
  .rt-sm thead tr td.rt-full, .rt-sm thead tr th.rt-full, .rt-sm tbody tr td.rt-full, .rt-sm tbody tr th.rt-full {
    flex-basis: 100%;
    flex-shrink: 0;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  }
  .rt-sm thead tr td.rt-header, .rt-sm thead tr th.rt-header, .rt-sm tbody tr td.rt-header, .rt-sm tbody tr th.rt-header {
    font-weight: 500;
  }
  .rt-sm thead tr td:first-child, .rt-sm thead tr th:first-child, .rt-sm tbody tr td:first-child, .rt-sm tbody tr th:first-child {
    padding-left: 1.5rem;
  }
  .rt-sm thead tr td:last-child, .rt-sm thead tr th:last-child, .rt-sm tbody tr td:last-child, .rt-sm tbody tr th:last-child {
    padding-right: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .rt-sm .rt-table-hide {
    display: none !important;
  }
  .rt-sm .rt-table-block {
    display: block !important;
  }
  .rt-sm .rt-table-inline {
    display: inline !important;
  }
  .rt-sm .progress {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .rt-md thead tr, .rt-md tbody tr {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e8ecf1;
  }
  .rt-md thead tr td, .rt-md thead tr th, .rt-md tbody tr td, .rt-md tbody tr th {
    border: none;
    padding: 0.75rem 0.75rem 0.75rem 1.5em;
  }
  .rt-md thead tr td.rt-full, .rt-md thead tr th.rt-full, .rt-md tbody tr td.rt-full, .rt-md tbody tr th.rt-full {
    flex-basis: 100%;
    flex-shrink: 0;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  }
  .rt-md thead tr td.rt-header, .rt-md thead tr th.rt-header, .rt-md tbody tr td.rt-header, .rt-md tbody tr th.rt-header {
    font-weight: 500;
  }
  .rt-md thead tr td:first-child, .rt-md thead tr th:first-child, .rt-md tbody tr td:first-child, .rt-md tbody tr th:first-child {
    padding-left: 1.5rem;
  }
  .rt-md thead tr td:last-child, .rt-md thead tr th:last-child, .rt-md tbody tr td:last-child, .rt-md tbody tr th:last-child {
    padding-right: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .rt-md .rt-table-hide {
    display: none !important;
  }
  .rt-md .rt-table-block {
    display: block !important;
  }
  .rt-md .rt-table-inline {
    display: inline !important;
  }
  .rt-md .progress {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .rt-lg thead tr, .rt-lg tbody tr {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e8ecf1;
  }
  .rt-lg thead tr td, .rt-lg thead tr th, .rt-lg tbody tr td, .rt-lg tbody tr th {
    border: none;
    padding: 0.75rem 0.75rem 0.75rem 1.5em;
  }
  .rt-lg thead tr td.rt-full, .rt-lg thead tr th.rt-full, .rt-lg tbody tr td.rt-full, .rt-lg tbody tr th.rt-full {
    flex-basis: 100%;
    flex-shrink: 0;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  }
  .rt-lg thead tr td.rt-header, .rt-lg thead tr th.rt-header, .rt-lg tbody tr td.rt-header, .rt-lg tbody tr th.rt-header {
    font-weight: 500;
  }
  .rt-lg thead tr td:first-child, .rt-lg thead tr th:first-child, .rt-lg tbody tr td:first-child, .rt-lg tbody tr th:first-child {
    padding-left: 1.5rem;
  }
  .rt-lg thead tr td:last-child, .rt-lg thead tr th:last-child, .rt-lg tbody tr td:last-child, .rt-lg tbody tr th:last-child {
    padding-right: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .rt-lg .rt-table-hide {
    display: none !important;
  }
  .rt-lg .rt-table-block {
    display: block !important;
  }
  .rt-lg .rt-table-inline {
    display: inline !important;
  }
  .rt-lg .progress {
    width: 100%;
  }
}

.rt thead tr, .rt tbody tr {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #e8ecf1;
}

.rt thead tr td, .rt thead tr th, .rt tbody tr td, .rt tbody tr th {
  border: none;
  padding: 0.75rem 0.75rem 0.75rem 1.5em;
}

.rt thead tr td.rt-full, .rt thead tr th.rt-full, .rt tbody tr td.rt-full, .rt tbody tr th.rt-full {
  flex-basis: 100%;
  flex-shrink: 0;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
}

.rt thead tr td.rt-header, .rt thead tr th.rt-header, .rt tbody tr td.rt-header, .rt tbody tr th.rt-header {
  font-weight: 500;
}

.rt thead tr td:first-child, .rt thead tr th:first-child, .rt tbody tr td:first-child, .rt tbody tr th:first-child {
  padding-left: 1.5rem;
}

.rt thead tr td:last-child, .rt thead tr th:last-child, .rt tbody tr td:last-child, .rt tbody tr th:last-child {
  padding-right: 1.5rem;
  margin-bottom: 0.75rem;
}

.rt .rt-table-hide {
  display: none !important;
}

.rt .rt-table-block {
  display: block !important;
}

.rt .rt-table-inline {
  display: inline !important;
}

.rt .progress {
  width: 100%;
}

/* Overwrite default Datatable styling */
.dataTables_wrapper select, .dataTables_wrapper input {
  width: 5rem;
  height: 2rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #e8ecf1;
}

.dataTables_wrapper input {
  width: 10rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #3a92ff !important;
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border-color: #e8ecf1 !important;
  box-shadow: noone;
  background: #f3f6fa !important;
  color: #576472 !important;
}

.dataTable {
  border-color: #e8ecf1 !important;
}

.dataTable td, .dataTable th {
  border-color: #e8ecf1 !important;
  padding: 1rem 0.75rem !important;
}

.datatable-header-cell {
  padding: 0.5rem 1rem;
}

.datatable-body-cell {
  padding: 0.5rem 1rem;
}

/* Forms */
.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #525f6c;
  background-color: #ffffff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #525f6c;
  background-color: #ffffff;
  border-color: #e9e5fd;
  outline: none;
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.25);
}

.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e8ecf1;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #525f6c;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.6rem + 1px);
  padding-bottom: calc(0.6rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.65625rem;
  line-height: 1.5;
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.4rem 1rem;
  font-size: 0.65625rem;
  line-height: 1.5;
  border-radius: 2px;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.78438rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.6rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.5125rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.valid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #1cbd35;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(28, 189, 53, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #1cbd35;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 189, 53, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #1cbd35;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(28, 189, 53, 0.25);
}

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #1cbd35;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #1cbd35;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 189, 53, 0.25);
}

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #eb4c5d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(235, 76, 93, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #eb4c5d;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 76, 93, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #eb4c5d;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(235, 76, 93, 0.25);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #eb4c5d;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #eb4c5d;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 76, 93, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #ffffff;
  background-color: #8670f2;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #f3f6fa, 0 0 0 0px rgba(134, 112, 242, 0.25);
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #ffffff;
  background-color: white;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e8ecf1;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.125rem;
  left: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 2px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #8670f2;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.3125rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #525f6c;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  appearance: none;
}

.custom-select:focus {
  border-color: #e9e5fd;
  outline: none;
}

.custom-select:focus::-ms-value {
  color: #525f6c;
  background-color: #ffffff;
}

.custom-select[multiple] {
  height: auto;
  background-image: none;
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e8ecf1;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.78438rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2.3125rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.3125rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  box-shadow: 0 0 0 0.075rem #ffffff, 0 0 0 0.2rem #8670f2;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.3125rem + 2px);
  padding: 0.5rem 1.5rem;
  line-height: 1.5;
  color: #525f6c;
  pointer-events: none;
  user-select: none;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.3125rem + 2px);
  padding: 0.5rem 1.5rem;
  line-height: 1.5;
  color: #525f6c;
  background-color: #e8ecf1;
  border: 1px solid #ced4da;
  border-radius: 0 2px 2px 0;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.form-control {
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-control::placeholder {
  color: #adb5bd;
}

.form-control:focus {
  border-color: #3a92ff;
}

.form-control-clear {
  border: 0;
}

.form-control-clear:focus {
  box-shadow: none;
}

.form-control.ng-touched.ng-invalid,
.custom-select.ng-touched.ng-invalid {
  border-color: #eb4c5d;
}

.form-control.ng-touched.ng-invalid:focus,
.custom-select.ng-touched.ng-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 76, 93, 0.25);
}

.form-check-input.ng-touched.ng-invalid + .form-check-label {
  color: #eb4c5d;
}

.form-control.ng-touched.ng-valid,
.custom-select.ng-touched.ng-valid {
  border-color: #1cbd35;
}

.form-control.ng-touched.ng-valid:focus,
.custom-select.ng-touched.ng-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 189, 53, 0.25);
}

.form-check-input.ng-touched.ng-valid + .form-check-label {
  color: #1cbd35;
}

.custom-control {
  padding-left: 2rem;
}

.custom-control-indicator.custom-control-material {
  border: 1px solid #ced4da;
  background-color: transparent;
}

.custom-control-input:checked ~ .custom-control-indicator.custom-control-material {
  border: 0;
}

.custom-control-input:checked ~ .custom-control-indicator-primary {
  background-color: #8670f2;
}

.custom-control-input:checked ~ .custom-control-indicator-secondary {
  background-color: #868e96;
}

.custom-control-input:checked ~ .custom-control-indicator-success {
  background-color: #1cbd35;
}

.custom-control-input:checked ~ .custom-control-indicator-info {
  background-color: #3a92ff;
}

.custom-control-input:checked ~ .custom-control-indicator-warning {
  background-color: #fd780a;
}

.custom-control-input:checked ~ .custom-control-indicator-danger {
  background-color: #eb4c5d;
}

.custom-control-input:checked ~ .custom-control-indicator-light {
  background-color: #f3f6fa;
}

.custom-control-input:checked ~ .custom-control-indicator-dark {
  background-color: #525f6c;
}

.custom-control-input:checked ~ .custom-control-indicator-teal {
  background-color: #26c1b2;
}

.custom-control-input:checked ~ .custom-control-indicator-indigo {
  background-color: #6610f2;
}

.custom-control-input:checked ~ .custom-control-indicator-cyan {
  background-color: #17a2b8;
}

.custom-control-input:checked ~ .custom-control-indicator-yellow {
  background-color: #ffc411;
}

.custom-control-input:checked ~ .custom-control-indicator-pink {
  background-color: #e83e8c;
}

.custom-control-indicator {
  top: 0;
}

.form-control.is-warning {
  border-color: #fd780a;
}

.form-control.is-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 120, 10, 0.25);
}

/* Overwrite default medium editor style */
.medium-editor-element {
  border: 1px solid #ced4da;
  padding: 1rem;
  min-height: 15rem !important;
  margin-bottom: 1rem;
}

.medium-editor-element:focus {
  outline: 0;
}

.medium-editor-action .fa {
  font-size: 0.9em !important;
}

/* Overwrite Dropzone defulat style */
.dropzone {
  background: #f3f6fa !important;
  border: 1px solid #ced4da !important;
  min-height: 120px !important;
  text-transform: uppercase;
}

/* Date Picker */
.ngb-dp-month .btn-light {
  box-shadow: none;
  border-radius: 2px !important;
  font-size: 0.8rem;
  color: #576472;
}

.ngb-dp-weekday {
  color: #8670f2 !important;
}

.ngb-dp-header {
  padding-top: 0.75rem !important;
}

ngb-datepicker {
  border-radius: 0 !important;
}

.btn-link.ngb-dp-arrow-btn {
  color: #8670f2 !important;
  font-size: 0.75rem !important;
}

.datepicker-primary .ngb-dp-weekday {
  color: #8670f2 !important;
}

.datepicker-primary .btn-link.ngb-dp-arrow-btn {
  color: #8670f2 !important;
}

.datepicker-primary .btn-light.bg-primary.text-white {
  background: #8670f2 !important;
}

.datepicker-secondary .ngb-dp-weekday {
  color: #868e96 !important;
}

.datepicker-secondary .btn-link.ngb-dp-arrow-btn {
  color: #868e96 !important;
}

.datepicker-secondary .btn-light.bg-primary.text-white {
  background: #868e96 !important;
}

.datepicker-success .ngb-dp-weekday {
  color: #1cbd35 !important;
}

.datepicker-success .btn-link.ngb-dp-arrow-btn {
  color: #1cbd35 !important;
}

.datepicker-success .btn-light.bg-primary.text-white {
  background: #1cbd35 !important;
}

.datepicker-info .ngb-dp-weekday {
  color: #3a92ff !important;
}

.datepicker-info .btn-link.ngb-dp-arrow-btn {
  color: #3a92ff !important;
}

.datepicker-info .btn-light.bg-primary.text-white {
  background: #3a92ff !important;
}

.datepicker-warning .ngb-dp-weekday {
  color: #fd780a !important;
}

.datepicker-warning .btn-link.ngb-dp-arrow-btn {
  color: #fd780a !important;
}

.datepicker-warning .btn-light.bg-primary.text-white {
  background: #fd780a !important;
}

.datepicker-danger .ngb-dp-weekday {
  color: #eb4c5d !important;
}

.datepicker-danger .btn-link.ngb-dp-arrow-btn {
  color: #eb4c5d !important;
}

.datepicker-danger .btn-light.bg-primary.text-white {
  background: #eb4c5d !important;
}

.datepicker-light .ngb-dp-weekday {
  color: #f3f6fa !important;
}

.datepicker-light .btn-link.ngb-dp-arrow-btn {
  color: #f3f6fa !important;
}

.datepicker-light .btn-light.bg-primary.text-white {
  background: #f3f6fa !important;
}

.datepicker-dark .ngb-dp-weekday {
  color: #525f6c !important;
}

.datepicker-dark .btn-link.ngb-dp-arrow-btn {
  color: #525f6c !important;
}

.datepicker-dark .btn-light.bg-primary.text-white {
  background: #525f6c !important;
}

.datepicker-teal .ngb-dp-weekday {
  color: #26c1b2 !important;
}

.datepicker-teal .btn-link.ngb-dp-arrow-btn {
  color: #26c1b2 !important;
}

.datepicker-teal .btn-light.bg-primary.text-white {
  background: #26c1b2 !important;
}

.datepicker-indigo .ngb-dp-weekday {
  color: #6610f2 !important;
}

.datepicker-indigo .btn-link.ngb-dp-arrow-btn {
  color: #6610f2 !important;
}

.datepicker-indigo .btn-light.bg-primary.text-white {
  background: #6610f2 !important;
}

.datepicker-cyan .ngb-dp-weekday {
  color: #17a2b8 !important;
}

.datepicker-cyan .btn-link.ngb-dp-arrow-btn {
  color: #17a2b8 !important;
}

.datepicker-cyan .btn-light.bg-primary.text-white {
  background: #17a2b8 !important;
}

.datepicker-yellow .ngb-dp-weekday {
  color: #ffc411 !important;
}

.datepicker-yellow .btn-link.ngb-dp-arrow-btn {
  color: #ffc411 !important;
}

.datepicker-yellow .btn-light.bg-primary.text-white {
  background: #ffc411 !important;
}

.datepicker-pink .ngb-dp-weekday {
  color: #e83e8c !important;
}

.datepicker-pink .btn-link.ngb-dp-arrow-btn {
  color: #e83e8c !important;
}

.datepicker-pink .btn-light.bg-primary.text-white {
  background: #e83e8c !important;
}

/* Overwrite Timepicker style */
.ngb-tp .btn-link {
  color: #8670f2;
}

.timepicker-primary .ngb-tp .btn-link {
  color: #8670f2;
}

.timepicker-secondary .ngb-tp .btn-link {
  color: #868e96;
}

.timepicker-success .ngb-tp .btn-link {
  color: #1cbd35;
}

.timepicker-info .ngb-tp .btn-link {
  color: #3a92ff;
}

.timepicker-warning .ngb-tp .btn-link {
  color: #fd780a;
}

.timepicker-danger .ngb-tp .btn-link {
  color: #eb4c5d;
}

.timepicker-light .ngb-tp .btn-link {
  color: #f3f6fa;
}

.timepicker-dark .ngb-tp .btn-link {
  color: #525f6c;
}

.timepicker-teal .ngb-tp .btn-link {
  color: #26c1b2;
}

.timepicker-indigo .ngb-tp .btn-link {
  color: #6610f2;
}

.timepicker-cyan .ngb-tp .btn-link {
  color: #17a2b8;
}

.timepicker-yellow .ngb-tp .btn-link {
  color: #ffc411;
}

.timepicker-pink .ngb-tp .btn-link {
  color: #e83e8c;
}

/* typeahed */
.tt-menu {
  background: white;
  width: 100%;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.tt-menu .tt-suggestion {
  padding: 1rem 1rem;
}

/* Input Group */
.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
}

.input-group-addon {
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f6c;
  text-align: center;
  background-color: #e8ecf1;
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.4rem 1rem;
  font-size: 0.65625rem;
  border-radius: 2px;
}

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.6rem 2.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}

.input-group-btn:first-child > .btn + .btn {
  margin-left: 0;
}

.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: 0;
}

.input-group-btn:not(:first-child) > .btn:first-child,
.input-group-btn:not(:first-child) > .btn-group:first-child {
  margin-left: -1px;
}

.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

/* Buttons */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #8670f2;
  border-color: #8670f2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #684dee;
  border-color: #5f42ed;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #8670f2;
  border-color: #8670f2;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #5f42ed;
  border-color: #5536ec;
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0px rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
  box-shadow: 0 0 0 0px rgba(134, 142, 150, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #1cbd35;
  border-color: #1cbd35;
}

.btn-success:hover {
  color: #fff;
  background-color: #179c2c;
  border-color: #159129;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0px rgba(28, 189, 53, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #1cbd35;
  border-color: #1cbd35;
}

.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #159129;
  border-color: #148525;
  box-shadow: 0 0 0 0px rgba(28, 189, 53, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #3a92ff;
  border-color: #3a92ff;
}

.btn-info:hover {
  color: #fff;
  background-color: #147dff;
  border-color: #0776ff;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0px rgba(58, 146, 255, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #3a92ff;
  border-color: #3a92ff;
}

.btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0776ff;
  border-color: #006ff9;
  box-shadow: 0 0 0 0px rgba(58, 146, 255, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #fd780a;
  border-color: #fd780a;
}

.btn-warning:hover {
  color: #fff;
  background-color: #df6602;
  border-color: #d26002;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0px rgba(253, 120, 10, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #fd780a;
  border-color: #fd780a;
}

.btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d26002;
  border-color: #c65a02;
  box-shadow: 0 0 0 0px rgba(253, 120, 10, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #eb4c5d;
  border-color: #eb4c5d;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e72a3e;
  border-color: #e61e33;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0px rgba(235, 76, 93, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #eb4c5d;
  border-color: #eb4c5d;
}

.btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e61e33;
  border-color: #de192e;
  box-shadow: 0 0 0 0px rgba(235, 76, 93, 0.5);
}

.btn-light {
  color: #111;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}

.btn-light:hover {
  color: #111;
  background-color: #d8e2ef;
  border-color: #cfdbeb;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0px rgba(243, 246, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}

.btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #111;
  background-color: #cfdbeb;
  border-color: #c6d4e7;
  box-shadow: 0 0 0 0px rgba(243, 246, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #525f6c;
  border-color: #525f6c;
}

.btn-dark:hover {
  color: #fff;
  background-color: #424c56;
  border-color: #3c464f;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0px rgba(82, 95, 108, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #525f6c;
  border-color: #525f6c;
}

.btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #3c464f;
  border-color: #373f48;
  box-shadow: 0 0 0 0px rgba(82, 95, 108, 0.5);
}

.btn-teal {
  color: #fff;
  background-color: #26c1b2;
  border-color: #26c1b2;
}

.btn-teal:hover {
  color: #fff;
  background-color: #20a195;
  border-color: #1e968b;
}

.btn-teal:focus, .btn-teal.focus {
  box-shadow: 0 0 0 0px rgba(38, 193, 178, 0.5);
}

.btn-teal.disabled, .btn-teal:disabled {
  background-color: #26c1b2;
  border-color: #26c1b2;
}

.btn-teal:not([disabled]):not(.disabled):active, .btn-teal:not([disabled]):not(.disabled).active,
.show > .btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #1e968b;
  border-color: #1c8c81;
  box-shadow: 0 0 0 0px rgba(38, 193, 178, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}

.btn-indigo:hover {
  color: #fff;
  background-color: #560bd0;
  border-color: #510bc4;
}

.btn-indigo:focus, .btn-indigo.focus {
  box-shadow: 0 0 0 0px rgba(102, 16, 242, 0.5);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  background-color: #6610f2;
  border-color: #6610f2;
}

.btn-indigo:not([disabled]):not(.disabled):active, .btn-indigo:not([disabled]):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #510bc4;
  border-color: #4c0ab8;
  box-shadow: 0 0 0 0px rgba(102, 16, 242, 0.5);
}

.btn-cyan {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-cyan:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-cyan:focus, .btn-cyan.focus {
  box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.5);
}

.btn-cyan.disabled, .btn-cyan:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-cyan:not([disabled]):not(.disabled):active, .btn-cyan:not([disabled]):not(.disabled).active,
.show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
  box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.5);
}

.btn-yellow {
  color: #111;
  background-color: #ffc411;
  border-color: #ffc411;
}

.btn-yellow:hover {
  color: #111;
  background-color: #eab000;
  border-color: #dda600;
}

.btn-yellow:focus, .btn-yellow.focus {
  box-shadow: 0 0 0 0px rgba(255, 196, 17, 0.5);
}

.btn-yellow.disabled, .btn-yellow:disabled {
  background-color: #ffc411;
  border-color: #ffc411;
}

.btn-yellow:not([disabled]):not(.disabled):active, .btn-yellow:not([disabled]):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
  color: #111;
  background-color: #dda600;
  border-color: #d09d00;
  box-shadow: 0 0 0 0px rgba(255, 196, 17, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.btn-pink:hover {
  color: #fff;
  background-color: #e41c78;
  border-color: #d91a72;
}

.btn-pink:focus, .btn-pink.focus {
  box-shadow: 0 0 0 0px rgba(232, 62, 140, 0.5);
}

.btn-pink.disabled, .btn-pink:disabled {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.btn-pink:not([disabled]):not(.disabled):active, .btn-pink:not([disabled]):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #d91a72;
  border-color: #ce196c;
  box-shadow: 0 0 0 0px rgba(232, 62, 140, 0.5);
}

.btn-outline-primary {
  color: #8670f2;
  background-color: transparent;
  background-image: none;
  border-color: #8670f2;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #8670f2;
  border-color: #8670f2;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #8670f2;
  background-color: transparent;
}

.btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #8670f2;
  border-color: #8670f2;
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0px rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #868e96;
  border-color: #868e96;
  box-shadow: 0 0 0 0px rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #1cbd35;
  background-color: transparent;
  background-image: none;
  border-color: #1cbd35;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #1cbd35;
  border-color: #1cbd35;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0px rgba(28, 189, 53, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #1cbd35;
  background-color: transparent;
}

.btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #1cbd35;
  border-color: #1cbd35;
  box-shadow: 0 0 0 0px rgba(28, 189, 53, 0.5);
}

.btn-outline-info {
  color: #3a92ff;
  background-color: transparent;
  background-image: none;
  border-color: #3a92ff;
}

.btn-outline-info:hover {
  color: #ffffff;
  background-color: #3a92ff;
  border-color: #3a92ff;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0px rgba(58, 146, 255, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3a92ff;
  background-color: transparent;
}

.btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #3a92ff;
  border-color: #3a92ff;
  box-shadow: 0 0 0 0px rgba(58, 146, 255, 0.5);
}

.btn-outline-warning {
  color: #fd780a;
  background-color: transparent;
  background-image: none;
  border-color: #fd780a;
}

.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #fd780a;
  border-color: #fd780a;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0px rgba(253, 120, 10, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fd780a;
  background-color: transparent;
}

.btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #fd780a;
  border-color: #fd780a;
  box-shadow: 0 0 0 0px rgba(253, 120, 10, 0.5);
}

.btn-outline-danger {
  color: #eb4c5d;
  background-color: transparent;
  background-image: none;
  border-color: #eb4c5d;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #eb4c5d;
  border-color: #eb4c5d;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0px rgba(235, 76, 93, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #eb4c5d;
  background-color: transparent;
}

.btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #eb4c5d;
  border-color: #eb4c5d;
  box-shadow: 0 0 0 0px rgba(235, 76, 93, 0.5);
}

.btn-outline-light {
  color: #f3f6fa;
  background-color: transparent;
  background-image: none;
  border-color: #f3f6fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0px rgba(243, 246, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f3f6fa;
  background-color: transparent;
}

.btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(243, 246, 250, 0.5);
}

.btn-outline-dark {
  color: #525f6c;
  background-color: transparent;
  background-image: none;
  border-color: #525f6c;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #525f6c;
  border-color: #525f6c;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0px rgba(82, 95, 108, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #525f6c;
  background-color: transparent;
}

.btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #525f6c;
  border-color: #525f6c;
  box-shadow: 0 0 0 0px rgba(82, 95, 108, 0.5);
}

.btn-outline-teal {
  color: #26c1b2;
  background-color: transparent;
  background-image: none;
  border-color: #26c1b2;
}

.btn-outline-teal:hover {
  color: #ffffff;
  background-color: #26c1b2;
  border-color: #26c1b2;
}

.btn-outline-teal:focus, .btn-outline-teal.focus {
  box-shadow: 0 0 0 0px rgba(38, 193, 178, 0.5);
}

.btn-outline-teal.disabled, .btn-outline-teal:disabled {
  color: #26c1b2;
  background-color: transparent;
}

.btn-outline-teal:not([disabled]):not(.disabled):active, .btn-outline-teal:not([disabled]):not(.disabled).active,
.show > .btn-outline-teal.dropdown-toggle {
  color: #ffffff;
  background-color: #26c1b2;
  border-color: #26c1b2;
  box-shadow: 0 0 0 0px rgba(38, 193, 178, 0.5);
}

.btn-outline-indigo {
  color: #6610f2;
  background-color: transparent;
  background-image: none;
  border-color: #6610f2;
}

.btn-outline-indigo:hover {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2;
}

.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0px rgba(102, 16, 242, 0.5);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #6610f2;
  background-color: transparent;
}

.btn-outline-indigo:not([disabled]):not(.disabled):active, .btn-outline-indigo:not([disabled]):not(.disabled).active,
.show > .btn-outline-indigo.dropdown-toggle {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2;
  box-shadow: 0 0 0 0px rgba(102, 16, 242, 0.5);
}

.btn-outline-cyan {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-cyan:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-cyan:focus, .btn-outline-cyan.focus {
  box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.5);
}

.btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-cyan:not([disabled]):not(.disabled):active, .btn-outline-cyan:not([disabled]):not(.disabled).active,
.show > .btn-outline-cyan.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.5);
}

.btn-outline-yellow {
  color: #ffc411;
  background-color: transparent;
  background-image: none;
  border-color: #ffc411;
}

.btn-outline-yellow:hover {
  color: #ffffff;
  background-color: #ffc411;
  border-color: #ffc411;
}

.btn-outline-yellow:focus, .btn-outline-yellow.focus {
  box-shadow: 0 0 0 0px rgba(255, 196, 17, 0.5);
}

.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
  color: #ffc411;
  background-color: transparent;
}

.btn-outline-yellow:not([disabled]):not(.disabled):active, .btn-outline-yellow:not([disabled]):not(.disabled).active,
.show > .btn-outline-yellow.dropdown-toggle {
  color: #ffffff;
  background-color: #ffc411;
  border-color: #ffc411;
  box-shadow: 0 0 0 0px rgba(255, 196, 17, 0.5);
}

.btn-outline-pink {
  color: #e83e8c;
  background-color: transparent;
  background-image: none;
  border-color: #e83e8c;
}

.btn-outline-pink:hover {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 0px rgba(232, 62, 140, 0.5);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #e83e8c;
  background-color: transparent;
}

.btn-outline-pink:not([disabled]):not(.disabled):active, .btn-outline-pink:not([disabled]):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c;
  box-shadow: 0 0 0 0px rgba(232, 62, 140, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3a92ff;
  background-color: transparent;
}

.btn-link:hover {
  color: #006aed;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #868e96;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.6rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 1rem;
  font-size: 0.65625rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn {
  cursor: pointer;
  font-size: 0.9rem;
}

.btn.btn-sm, .btn-group-sm > .btn {
  font-size: 0.8rem;
}

.btn.btn-lg, .btn-group-lg > .btn {
  font-size: 1.1rem;
}

.btn .material-icons {
  line-height: 1;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
}

.btn .fa {
  vertical-align: middle;
}

.btn.no-shadow {
  box-shadow: none !important;
}

.btn-fab {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;
  padding: 0;
  line-height: 3.25rem;
  font-size: 1rem;
}

.btn-fab.btn-lg, .btn-group-lg > .btn-fab.btn {
  width: 4.25rem;
  height: 4.25rem;
  line-height: 4.25rem;
  font-size: 1.25rem;
}

.btn-fab.btn-sm, .btn-group-sm > .btn-fab.btn {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  font-size: 0.9rem;
}

.btn-fab.btn-xs {
  width: 1.5rem;
  height: 15rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
}

.btn-fab.fixed-bottom-right {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 5;
}

.btn-icon {
  width: 3rem;
  height: 2.7rem;
  padding: 0;
  line-height: 2.6rem;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  width: 4.25rem;
  height: 4.25rem;
  line-height: 4.25rem;
  font-size: 1.25rem;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  font-size: 0.9rem;
}

.btn-icon.btn-xs {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
}

.btn-pill {
  border-radius: 10em;
}

.btn-primary {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-primary:focus, .btn-primary:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-primary {
  color: #8670f2;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-primary:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-primary:focus, .btn-flat-primary.focus {
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.5);
}

.btn-flat-primary.disabled, .btn-flat-primary:disabled {
  color: #8670f2;
  background-color: transparent;
}

.btn-flat-primary:not([disabled]):not(.disabled):active, .btn-flat-primary:not([disabled]):not(.disabled).active,
.show > .btn-flat-primary.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(134, 112, 242, 0.5);
}

.btn-flat-primary.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-primary:focus, .btn-flat-primary:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-primary.no-shadow:focus {
  box-shadow: none !important;
}

.btn-secondary {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-secondary:focus, .btn-secondary:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-secondary:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-secondary:focus, .btn-flat-secondary.focus {
  box-shadow: 0 0 0 0px rgba(134, 142, 150, 0.5);
}

.btn-flat-secondary.disabled, .btn-flat-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-flat-secondary:not([disabled]):not(.disabled):active, .btn-flat-secondary:not([disabled]):not(.disabled).active,
.show > .btn-flat-secondary.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(134, 142, 150, 0.5);
}

.btn-flat-secondary.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-secondary:focus, .btn-flat-secondary:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-secondary.no-shadow:focus {
  box-shadow: none !important;
}

.btn-success {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-success:focus, .btn-success:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-success {
  color: #1cbd35;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-success:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-success:focus, .btn-flat-success.focus {
  box-shadow: 0 0 0 0px rgba(28, 189, 53, 0.5);
}

.btn-flat-success.disabled, .btn-flat-success:disabled {
  color: #1cbd35;
  background-color: transparent;
}

.btn-flat-success:not([disabled]):not(.disabled):active, .btn-flat-success:not([disabled]):not(.disabled).active,
.show > .btn-flat-success.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(28, 189, 53, 0.5);
}

.btn-flat-success.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-success:focus, .btn-flat-success:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-success.no-shadow:focus {
  box-shadow: none !important;
}

.btn-info {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-info:focus, .btn-info:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-info {
  color: #3a92ff;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-info:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-info:focus, .btn-flat-info.focus {
  box-shadow: 0 0 0 0px rgba(58, 146, 255, 0.5);
}

.btn-flat-info.disabled, .btn-flat-info:disabled {
  color: #3a92ff;
  background-color: transparent;
}

.btn-flat-info:not([disabled]):not(.disabled):active, .btn-flat-info:not([disabled]):not(.disabled).active,
.show > .btn-flat-info.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(58, 146, 255, 0.5);
}

.btn-flat-info.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-info:focus, .btn-flat-info:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-info.no-shadow:focus {
  box-shadow: none !important;
}

.btn-warning {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-warning:focus, .btn-warning:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-warning {
  color: #fd780a;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-warning:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-warning:focus, .btn-flat-warning.focus {
  box-shadow: 0 0 0 0px rgba(253, 120, 10, 0.5);
}

.btn-flat-warning.disabled, .btn-flat-warning:disabled {
  color: #fd780a;
  background-color: transparent;
}

.btn-flat-warning:not([disabled]):not(.disabled):active, .btn-flat-warning:not([disabled]):not(.disabled).active,
.show > .btn-flat-warning.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(253, 120, 10, 0.5);
}

.btn-flat-warning.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-warning:focus, .btn-flat-warning:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-warning.no-shadow:focus {
  box-shadow: none !important;
}

.btn-danger {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-danger:focus, .btn-danger:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-danger {
  color: #eb4c5d;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-danger:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-danger:focus, .btn-flat-danger.focus {
  box-shadow: 0 0 0 0px rgba(235, 76, 93, 0.5);
}

.btn-flat-danger.disabled, .btn-flat-danger:disabled {
  color: #eb4c5d;
  background-color: transparent;
}

.btn-flat-danger:not([disabled]):not(.disabled):active, .btn-flat-danger:not([disabled]):not(.disabled).active,
.show > .btn-flat-danger.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(235, 76, 93, 0.5);
}

.btn-flat-danger.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-danger:focus, .btn-flat-danger:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-danger.no-shadow:focus {
  box-shadow: none !important;
}

.btn-light {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-light:focus, .btn-light:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-light {
  color: #f3f6fa;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-light:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-light:focus, .btn-flat-light.focus {
  box-shadow: 0 0 0 0px rgba(243, 246, 250, 0.5);
}

.btn-flat-light.disabled, .btn-flat-light:disabled {
  color: #f3f6fa;
  background-color: transparent;
}

.btn-flat-light:not([disabled]):not(.disabled):active, .btn-flat-light:not([disabled]):not(.disabled).active,
.show > .btn-flat-light.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(243, 246, 250, 0.5);
}

.btn-flat-light.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-light:focus, .btn-flat-light:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-light.no-shadow:focus {
  box-shadow: none !important;
}

.btn-dark {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-dark:focus, .btn-dark:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-dark {
  color: #525f6c;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-dark:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-dark:focus, .btn-flat-dark.focus {
  box-shadow: 0 0 0 0px rgba(82, 95, 108, 0.5);
}

.btn-flat-dark.disabled, .btn-flat-dark:disabled {
  color: #525f6c;
  background-color: transparent;
}

.btn-flat-dark:not([disabled]):not(.disabled):active, .btn-flat-dark:not([disabled]):not(.disabled).active,
.show > .btn-flat-dark.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(82, 95, 108, 0.5);
}

.btn-flat-dark.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-dark:focus, .btn-flat-dark:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-dark.no-shadow:focus {
  box-shadow: none !important;
}

.btn-teal {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-teal:focus, .btn-teal:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-teal {
  color: #26c1b2;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-teal:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-teal:focus, .btn-flat-teal.focus {
  box-shadow: 0 0 0 0px rgba(38, 193, 178, 0.5);
}

.btn-flat-teal.disabled, .btn-flat-teal:disabled {
  color: #26c1b2;
  background-color: transparent;
}

.btn-flat-teal:not([disabled]):not(.disabled):active, .btn-flat-teal:not([disabled]):not(.disabled).active,
.show > .btn-flat-teal.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(38, 193, 178, 0.5);
}

.btn-flat-teal.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-teal:focus, .btn-flat-teal:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-teal.no-shadow:focus {
  box-shadow: none !important;
}

.btn-indigo {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-indigo:focus, .btn-indigo:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-indigo {
  color: #6610f2;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-indigo:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-indigo:focus, .btn-flat-indigo.focus {
  box-shadow: 0 0 0 0px rgba(102, 16, 242, 0.5);
}

.btn-flat-indigo.disabled, .btn-flat-indigo:disabled {
  color: #6610f2;
  background-color: transparent;
}

.btn-flat-indigo:not([disabled]):not(.disabled):active, .btn-flat-indigo:not([disabled]):not(.disabled).active,
.show > .btn-flat-indigo.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(102, 16, 242, 0.5);
}

.btn-flat-indigo.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-indigo:focus, .btn-flat-indigo:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-indigo.no-shadow:focus {
  box-shadow: none !important;
}

.btn-cyan {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-cyan:focus, .btn-cyan:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-cyan {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-cyan:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-cyan:focus, .btn-flat-cyan.focus {
  box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.5);
}

.btn-flat-cyan.disabled, .btn-flat-cyan:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-flat-cyan:not([disabled]):not(.disabled):active, .btn-flat-cyan:not([disabled]):not(.disabled).active,
.show > .btn-flat-cyan.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(23, 162, 184, 0.5);
}

.btn-flat-cyan.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-cyan:focus, .btn-flat-cyan:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-cyan.no-shadow:focus {
  box-shadow: none !important;
}

.btn-yellow {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-yellow:focus, .btn-yellow:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-yellow {
  color: #ffc411;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-yellow:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-yellow:focus, .btn-flat-yellow.focus {
  box-shadow: 0 0 0 0px rgba(255, 196, 17, 0.5);
}

.btn-flat-yellow.disabled, .btn-flat-yellow:disabled {
  color: #ffc411;
  background-color: transparent;
}

.btn-flat-yellow:not([disabled]):not(.disabled):active, .btn-flat-yellow:not([disabled]):not(.disabled).active,
.show > .btn-flat-yellow.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(255, 196, 17, 0.5);
}

.btn-flat-yellow.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-yellow:focus, .btn-flat-yellow:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-yellow.no-shadow:focus {
  box-shadow: none !important;
}

.btn-pink {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-pink:focus, .btn-pink:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-pink {
  color: #e83e8c;
  background-color: transparent;
  background-image: none;
  border-color: none;
}

.btn-flat-pink:hover {
  background-color: #f3f6fa;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-flat-pink:focus, .btn-flat-pink.focus {
  box-shadow: 0 0 0 0px rgba(232, 62, 140, 0.5);
}

.btn-flat-pink.disabled, .btn-flat-pink:disabled {
  color: #e83e8c;
  background-color: transparent;
}

.btn-flat-pink:not([disabled]):not(.disabled):active, .btn-flat-pink:not([disabled]):not(.disabled).active,
.show > .btn-flat-pink.dropdown-toggle {
  background-color: #f3f6fa;
  box-shadow: 0 0 0 0px rgba(232, 62, 140, 0.5);
}

.btn-flat-pink.no-shadow:hover {
  box-shadow: none;
}

.btn-flat-pink:focus, .btn-flat-pink:active {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.btn-flat-pink.no-shadow:focus {
  box-shadow: none !important;
}

.btn-group {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-group .btn {
  box-shadow: none !important;
}

/* Button Group */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/* Transitions */
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

/* Dropdown */
.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #576472;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e8ecf1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f3f6fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #8670f2;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.65625rem;
  color: #868e96;
  white-space: nowrap;
}

.dropdown.no-caret > .dropdown-toggle::after {
  content: none;
  display: none;
}

.dropdown-item.active, .dropdown-item:active {
  background: #8670f2 !important;
  color: white !important;
}

/* Navigation and Tabs */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e8ecf1 #e8ecf1 #ddd;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #525f6c;
  background-color: #f3f6fa;
  border-color: #ddd #ddd #f3f6fa;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #8670f2;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.list-nav {
  display: block;
  margin: 0;
  padding: 0;
}

.list-nav-group-title {
  border-bottom: none;
  padding: 1.25rem 1.5rem 1rem;
  font-size: 0.95em;
}

.list-nav-item {
  display: block;
  margin: 0;
  padding: 0;
}

.list-nav-item .list-nav-child {
  display: block;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: hidden;
}

.list-nav-item.active > .list-nav-link {
  font-weight: 500;
}

.list-nav-item.active .list-nav-child {
  height: auto;
}

.list-nav-item.list-nav-child-item {
  border-bottom: none;
}

.list-nav > .list-nav-item > .list-nav-link {
  transition: background-color 0.5s ease;
}

.list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  background: none;
  font-weight: inherit;
}

.list-nav-link {
  display: flex;
  line-height: 2.1rem;
  padding: 0.6rem 1rem;
  text-decoration: none;
  color: inherit;
}

.list-nav-link:hover {
  text-decoration: none;
  color: inherit;
}

.list-nav-link:focus, .list-nav-link:active {
  outline: none;
}

.list-nav-child-item .list-nav-link {
  padding: 0.4rem 1rem;
  font-size: 1em;
}

.list-nav-child-item.active .list-nav-link {
  padding: 0.4rem 1rem;
  font-size: 1em;
}

.list-nav-icon {
  color: inherit;
  transition: background-color 0.5s ease;
  display: block;
  width: 2.1rem;
  height: 2.1rem;
  min-width: 2.1rem;
  line-height: 2.1rem;
  text-align: center;
  border-radius: 2px;
  background-color: rgba(100, 120, 140, 0.1);
}

.list-nav-icon .material-icons {
  font-size: 1.05em;
}

.list-nav-icon-sm {
  background: none;
  text-align: center;
}

.list-nav-icon-sm .material-icons {
  font-size: 1em;
}

.list-nav-expand {
  height: 2.1rem;
  color: inherit;
  line-height: 2.1rem;
  padding-right: 0.25rem;
  font-size: 0.6em;
}

.list-nav-label {
  display: block;
  width: 100%;
  padding-left: 1rem;
}

.list-nav-light {
  color: #5d6c7a;
}

.list-nav-light .list-nav-icon {
  color: #868e96;
}

.list-nav-light .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #8670f2;
  color: white;
}

.list-nav-light .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light .list-nav-child .list-nav-item.active .list-nav-link {
  color: #8670f2;
}

.list-nav-light .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #8670f2;
}

.list-nav-light .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #8670f2;
}

.list-nav-light.list-nav-light-primary {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-primary .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-primary .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-primary > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-primary > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-primary > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #8670f2;
  color: white;
}

.list-nav-light.list-nav-light-primary .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-primary .list-nav-child .list-nav-item.active .list-nav-link {
  color: #8670f2;
}

.list-nav-light.list-nav-light-primary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #8670f2;
}

.list-nav-light.list-nav-light-primary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #8670f2;
}

.list-nav-light.list-nav-light-secondary {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-secondary .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-secondary .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-secondary > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-secondary > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-secondary > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #868e96;
  color: white;
}

.list-nav-light.list-nav-light-secondary .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-secondary .list-nav-child .list-nav-item.active .list-nav-link {
  color: #868e96;
}

.list-nav-light.list-nav-light-secondary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-secondary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #868e96;
}

.list-nav-light.list-nav-light-success {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-success .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-success .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-success > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-success > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-success > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #1cbd35;
  color: white;
}

.list-nav-light.list-nav-light-success .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-success .list-nav-child .list-nav-item.active .list-nav-link {
  color: #1cbd35;
}

.list-nav-light.list-nav-light-success .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #1cbd35;
}

.list-nav-light.list-nav-light-success .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #1cbd35;
}

.list-nav-light.list-nav-light-info {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-info .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-info .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-info > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-info > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-info > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #3a92ff;
  color: white;
}

.list-nav-light.list-nav-light-info .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-info .list-nav-child .list-nav-item.active .list-nav-link {
  color: #3a92ff;
}

.list-nav-light.list-nav-light-info .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #3a92ff;
}

.list-nav-light.list-nav-light-info .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #3a92ff;
}

.list-nav-light.list-nav-light-warning {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-warning .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-warning .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-warning > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-warning > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-warning > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #fd780a;
  color: white;
}

.list-nav-light.list-nav-light-warning .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-warning .list-nav-child .list-nav-item.active .list-nav-link {
  color: #fd780a;
}

.list-nav-light.list-nav-light-warning .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #fd780a;
}

.list-nav-light.list-nav-light-warning .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #fd780a;
}

.list-nav-light.list-nav-light-danger {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-danger .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-danger .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-danger > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-danger > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-danger > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #eb4c5d;
  color: white;
}

.list-nav-light.list-nav-light-danger .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-danger .list-nav-child .list-nav-item.active .list-nav-link {
  color: #eb4c5d;
}

.list-nav-light.list-nav-light-danger .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #eb4c5d;
}

.list-nav-light.list-nav-light-danger .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #eb4c5d;
}

.list-nav-light.list-nav-light-light {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-light .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-light .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-light > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-light > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-light > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #f3f6fa;
  color: white;
}

.list-nav-light.list-nav-light-light .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-light .list-nav-child .list-nav-item.active .list-nav-link {
  color: #f3f6fa;
}

.list-nav-light.list-nav-light-light .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #f3f6fa;
}

.list-nav-light.list-nav-light-light .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #f3f6fa;
}

.list-nav-light.list-nav-light-dark {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-dark .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-dark .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-dark > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-dark > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-dark > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #525f6c;
  color: white;
}

.list-nav-light.list-nav-light-dark .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-dark .list-nav-child .list-nav-item.active .list-nav-link {
  color: #525f6c;
}

.list-nav-light.list-nav-light-dark .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #525f6c;
}

.list-nav-light.list-nav-light-dark .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #525f6c;
}

.list-nav-light.list-nav-light-teal {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-teal .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-teal .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-teal > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-teal > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-teal > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #26c1b2;
  color: white;
}

.list-nav-light.list-nav-light-teal .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-teal .list-nav-child .list-nav-item.active .list-nav-link {
  color: #26c1b2;
}

.list-nav-light.list-nav-light-teal .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #26c1b2;
}

.list-nav-light.list-nav-light-teal .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #26c1b2;
}

.list-nav-light.list-nav-light-indigo {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-indigo .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-indigo .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-indigo > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-indigo > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-indigo > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #6610f2;
  color: white;
}

.list-nav-light.list-nav-light-indigo .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-indigo .list-nav-child .list-nav-item.active .list-nav-link {
  color: #6610f2;
}

.list-nav-light.list-nav-light-indigo .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #6610f2;
}

.list-nav-light.list-nav-light-indigo .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #6610f2;
}

.list-nav-light.list-nav-light-cyan {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-cyan .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-cyan .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-cyan > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-cyan > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-cyan > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #17a2b8;
  color: white;
}

.list-nav-light.list-nav-light-cyan .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-cyan .list-nav-child .list-nav-item.active .list-nav-link {
  color: #17a2b8;
}

.list-nav-light.list-nav-light-cyan .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #17a2b8;
}

.list-nav-light.list-nav-light-cyan .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #17a2b8;
}

.list-nav-light.list-nav-light-yellow {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-yellow .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-yellow .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-yellow > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-yellow > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-yellow > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #ffc411;
  color: white;
}

.list-nav-light.list-nav-light-yellow .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-yellow .list-nav-child .list-nav-item.active .list-nav-link {
  color: #ffc411;
}

.list-nav-light.list-nav-light-yellow .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #ffc411;
}

.list-nav-light.list-nav-light-yellow .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #ffc411;
}

.list-nav-light.list-nav-light-pink {
  color: #5d6c7a;
}

.list-nav-light.list-nav-light-pink .list-nav-icon {
  color: #868e96;
}

.list-nav-light.list-nav-light-pink .list-nav-child {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-pink > .list-nav-item.active {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-pink > .list-nav-item.active > .list-nav-link {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-pink > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #e83e8c;
  color: white;
}

.list-nav-light.list-nav-light-pink .list-nav-link:hover {
  background-color: #f3f6fa;
}

.list-nav-light.list-nav-light-pink .list-nav-child .list-nav-item.active .list-nav-link {
  color: #e83e8c;
}

.list-nav-light.list-nav-light-pink .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #e83e8c;
}

.list-nav-light.list-nav-light-pink .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #e83e8c;
}

.list-nav-dark {
  color: #bfc7cf;
}

.list-nav-dark .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #8670f2;
  color: white;
}

.list-nav-dark .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark .list-nav-child .list-nav-item.active .list-nav-link {
  color: #8670f2;
}

.list-nav-dark .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #8670f2;
}

.list-nav-dark .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #8670f2;
}

.list-nav-dark.list-nav-dark-primary {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-primary .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-primary .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-primary > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-primary > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-primary > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #8670f2;
  color: white;
}

.list-nav-dark.list-nav-dark-primary .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-primary .list-nav-child .list-nav-item.active .list-nav-link {
  color: #8670f2;
}

.list-nav-dark.list-nav-dark-primary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #8670f2;
}

.list-nav-dark.list-nav-dark-primary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #8670f2;
}

.list-nav-dark.list-nav-dark-secondary {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-secondary .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-secondary .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-secondary > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-secondary > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-secondary > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #868e96;
  color: white;
}

.list-nav-dark.list-nav-dark-secondary .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-secondary .list-nav-child .list-nav-item.active .list-nav-link {
  color: #868e96;
}

.list-nav-dark.list-nav-dark-secondary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #868e96;
}

.list-nav-dark.list-nav-dark-secondary .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #868e96;
}

.list-nav-dark.list-nav-dark-success {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-success .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-success .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-success > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-success > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-success > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #1cbd35;
  color: white;
}

.list-nav-dark.list-nav-dark-success .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-success .list-nav-child .list-nav-item.active .list-nav-link {
  color: #1cbd35;
}

.list-nav-dark.list-nav-dark-success .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #1cbd35;
}

.list-nav-dark.list-nav-dark-success .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #1cbd35;
}

.list-nav-dark.list-nav-dark-info {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-info .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-info .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-info > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-info > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-info > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #3a92ff;
  color: white;
}

.list-nav-dark.list-nav-dark-info .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-info .list-nav-child .list-nav-item.active .list-nav-link {
  color: #3a92ff;
}

.list-nav-dark.list-nav-dark-info .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #3a92ff;
}

.list-nav-dark.list-nav-dark-info .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #3a92ff;
}

.list-nav-dark.list-nav-dark-warning {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-warning .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-warning .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-warning > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-warning > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-warning > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #fd780a;
  color: white;
}

.list-nav-dark.list-nav-dark-warning .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-warning .list-nav-child .list-nav-item.active .list-nav-link {
  color: #fd780a;
}

.list-nav-dark.list-nav-dark-warning .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #fd780a;
}

.list-nav-dark.list-nav-dark-warning .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #fd780a;
}

.list-nav-dark.list-nav-dark-danger {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-danger .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-danger .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-danger > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-danger > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-danger > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #eb4c5d;
  color: white;
}

.list-nav-dark.list-nav-dark-danger .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-danger .list-nav-child .list-nav-item.active .list-nav-link {
  color: #eb4c5d;
}

.list-nav-dark.list-nav-dark-danger .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #eb4c5d;
}

.list-nav-dark.list-nav-dark-danger .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #eb4c5d;
}

.list-nav-dark.list-nav-dark-light {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-light .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-light .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-light > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-light > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-light > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #f3f6fa;
  color: white;
}

.list-nav-dark.list-nav-dark-light .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-light .list-nav-child .list-nav-item.active .list-nav-link {
  color: #f3f6fa;
}

.list-nav-dark.list-nav-dark-light .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #f3f6fa;
}

.list-nav-dark.list-nav-dark-light .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #f3f6fa;
}

.list-nav-dark.list-nav-dark-dark {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-dark .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-dark .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-dark > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-dark > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-dark > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #525f6c;
  color: white;
}

.list-nav-dark.list-nav-dark-dark .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-dark .list-nav-child .list-nav-item.active .list-nav-link {
  color: #525f6c;
}

.list-nav-dark.list-nav-dark-dark .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #525f6c;
}

.list-nav-dark.list-nav-dark-dark .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #525f6c;
}

.list-nav-dark.list-nav-dark-teal {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-teal .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-teal .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-teal > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-teal > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-teal > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #26c1b2;
  color: white;
}

.list-nav-dark.list-nav-dark-teal .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-teal .list-nav-child .list-nav-item.active .list-nav-link {
  color: #26c1b2;
}

.list-nav-dark.list-nav-dark-teal .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #26c1b2;
}

.list-nav-dark.list-nav-dark-teal .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #26c1b2;
}

.list-nav-dark.list-nav-dark-indigo {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-indigo .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-indigo .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-indigo > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-indigo > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-indigo > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #6610f2;
  color: white;
}

.list-nav-dark.list-nav-dark-indigo .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-indigo .list-nav-child .list-nav-item.active .list-nav-link {
  color: #6610f2;
}

.list-nav-dark.list-nav-dark-indigo .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #6610f2;
}

.list-nav-dark.list-nav-dark-indigo .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #6610f2;
}

.list-nav-dark.list-nav-dark-cyan {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-cyan .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-cyan .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-cyan > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-cyan > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-cyan > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #17a2b8;
  color: white;
}

.list-nav-dark.list-nav-dark-cyan .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-cyan .list-nav-child .list-nav-item.active .list-nav-link {
  color: #17a2b8;
}

.list-nav-dark.list-nav-dark-cyan .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #17a2b8;
}

.list-nav-dark.list-nav-dark-cyan .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #17a2b8;
}

.list-nav-dark.list-nav-dark-yellow {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-yellow .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-yellow .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-yellow > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-yellow > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-yellow > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #ffc411;
  color: white;
}

.list-nav-dark.list-nav-dark-yellow .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-yellow .list-nav-child .list-nav-item.active .list-nav-link {
  color: #ffc411;
}

.list-nav-dark.list-nav-dark-yellow .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #ffc411;
}

.list-nav-dark.list-nav-dark-yellow .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #ffc411;
}

.list-nav-dark.list-nav-dark-pink {
  color: #bfc7cf;
}

.list-nav-dark.list-nav-dark-pink .list-nav-icon {
  color: #ced4da;
}

.list-nav-dark.list-nav-dark-pink .list-nav-child {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-pink > .list-nav-item.active {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-pink > .list-nav-item.active > .list-nav-link {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-pink > .list-nav-item.active > .list-nav-link > .list-nav-icon {
  background: #e83e8c;
  color: white;
}

.list-nav-dark.list-nav-dark-pink .list-nav-link:hover {
  background-color: #3c464f;
}

.list-nav-dark.list-nav-dark-pink .list-nav-child .list-nav-item.active .list-nav-link {
  color: #e83e8c;
}

.list-nav-dark.list-nav-dark-pink .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon {
  color: #e83e8c;
}

.list-nav-dark.list-nav-dark-pink .list-nav-child .list-nav-item.active .list-nav-link .list-nav-icon .material-icons {
  color: #e83e8c;
}

.nav-material {
  border-bottom: 1px solid #e8ecf1;
  padding-left: 0.25rem;
}

.nav-material .nav-item {
  margin-bottom: -1px;
}

.nav-material .nav-item .nav-link {
  font-weight: 400;
  padding: 0.75rem 1.5rem;
  font-size: 1em;
  border-radius: 0;
  margin-right: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  background: none;
  position: relative;
  color: inherit;
  top: 0px;
}

.nav-material .nav-item .nav-link:focus {
  outline: 0;
}

.nav-material .nav-item .nav-link:hover {
  background: none;
  color: inherit;
}

.nav-material .nav-item .nav-link.disabled {
  cursor: not-allowed;
  color: #adb5bd;
}

.nav-material .nav-item .nav-link.active {
  border: 0;
  position: relative;
  font-weight: 500;
  border-bottom: 2px solid #8670f2;
}

.nav-material-primary .nav-item .nav-link.active {
  border-bottom-color: #8670f2;
}

.nav-material-secondary .nav-item .nav-link.active {
  border-bottom-color: #868e96;
}

.nav-material-success .nav-item .nav-link.active {
  border-bottom-color: #1cbd35;
}

.nav-material-info .nav-item .nav-link.active {
  border-bottom-color: #3a92ff;
}

.nav-material-warning .nav-item .nav-link.active {
  border-bottom-color: #fd780a;
}

.nav-material-danger .nav-item .nav-link.active {
  border-bottom-color: #eb4c5d;
}

.nav-material-light .nav-item .nav-link.active {
  border-bottom-color: #f3f6fa;
}

.nav-material-dark .nav-item .nav-link.active {
  border-bottom-color: #525f6c;
}

.nav-material-teal .nav-item .nav-link.active {
  border-bottom-color: #26c1b2;
}

.nav-material-indigo .nav-item .nav-link.active {
  border-bottom-color: #6610f2;
}

.nav-material-cyan .nav-item .nav-link.active {
  border-bottom-color: #17a2b8;
}

.nav-material-yellow .nav-item .nav-link.active {
  border-bottom-color: #ffc411;
}

.nav-material-pink .nav-item .nav-link.active {
  border-bottom-color: #e83e8c;
}

.nav-pills .nav-item {
  margin-bottom: -1px;
}

.nav-pills .nav-item .nav-link {
  font-weight: 400;
  padding: 0.6rem 1rem;
  font-size: 1em;
  border-radius: 0;
  margin-right: 0;
  border: 0;
  color: inherit;
  background: none;
  position: relative;
  top: 0;
}

.nav-pills .nav-item .nav-link:focus {
  outline: 0;
}

.nav-pills .nav-item .nav-link:hover {
  background: none;
  color: inherit;
}

.nav-pills .nav-item .nav-link.disabled {
  cursor: not-allowed;
  color: #adb5bd;
}

.nav-pills .nav-item .nav-link.active {
  background: #8670f2;
  color: white;
}

.nav-pills-primary .nav-item .nav-link.active {
  background: #8670f2;
}

.nav-pills-secondary .nav-item .nav-link.active {
  background: #868e96;
}

.nav-pills-success .nav-item .nav-link.active {
  background: #1cbd35;
}

.nav-pills-info .nav-item .nav-link.active {
  background: #3a92ff;
}

.nav-pills-warning .nav-item .nav-link.active {
  background: #fd780a;
}

.nav-pills-danger .nav-item .nav-link.active {
  background: #eb4c5d;
}

.nav-pills-light .nav-item .nav-link.active {
  background: #f3f6fa;
}

.nav-pills-dark .nav-item .nav-link.active {
  background: #525f6c;
}

.nav-pills-teal .nav-item .nav-link.active {
  background: #26c1b2;
}

.nav-pills-indigo .nav-item .nav-link.active {
  background: #6610f2;
}

.nav-pills-cyan .nav-item .nav-link.active {
  background: #17a2b8;
}

.nav-pills-yellow .nav-item .nav-link.active {
  background: #ffc411;
}

.nav-pills-pink .nav-item .nav-link.active {
  background: #e83e8c;
}

/* Navbar */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 0.875rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #ffffff;
}

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #ffffff;
}

/* Cards */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(2px - 1px) calc(2px - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(2px - 1px) calc(2px - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(2px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(2px - 1px);
  border-top-right-radius: calc(2px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(2px - 1px);
  border-bottom-left-radius: calc(2px - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group .card:only-child {
    border-radius: 2px;
  }
  .card-group .card:only-child .card-img-top {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .card-group .card:only-child .card-img-bottom {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card {
  border: 1px solid #e8ecf1;
  position: relative;
}

.card-fullscreen {
  position: fixed;
  border: none !important;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 21;
  height: 100%;
  overflow-y: auto !important;
}

.card-fullscreen .card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.card-fullscreen .card-img-top {
  max-height: 50vh;
  object-fit: cover;
}

.card-collapsed .card-body {
  padding-top: 0;
  padding-bottom: 0;
  box-sizing: border-box;
  height: 0;
  overflow-y: hidden;
}

.card-body {
  position: relative;
  padding: 1.3rem 1.5rem;
}

.card-header {
  position: relative;
  border: none;
  background: none;
  padding: 1.3rem 1.5rem;
}

.card-header h1, .card-header h2, .card-header h3, .card-header h4, .card-header h5 {
  margin: 0;
}

.card-header h1 + p, .card-header h2 + p, .card-header h3 + p, .card-header h4 + p, .card-header h5 + p {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.card-header + .card-body {
  padding-top: 0;
}

.card-header + .card-body.card-body-p {
  padding-top: 1.25rem;
}

.card-actions {
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1.4rem;
  top: 1.2rem;
}

.card-actions::after {
  display: block;
  clear: both;
  content: "";
}

.card-actions li {
  display: block;
  margin: 0;
  padding: 0;
  float: left;
}

.card-actions li a, .card-actions li button {
  display: block;
  width: 1.8rem;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  height: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  font-size: 0.8rem;
  color: #868e96;
  border-radius: 50%;
  margin-left: 0.3rem;
  cursor: pointer;
}

.card-actions li a:hover, .card-actions li button:hover {
  background: rgba(100, 100, 100, 0.1);
}

.card-footer {
  background: none;
}

/* Breadcrumbs */
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e8ecf1;
  border-radius: 2px;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #868e96;
}

/* Pagination */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.page-item.active .page-link {
  z-index: 2;
  color: #ffffff;
  background-color: #3a92ff;
  border-color: #3a92ff;
}

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #868e96;
  background-color: #ffffff;
  border: 1px solid #ddd;
}

.page-link:focus, .page-link:hover {
  color: #006aed;
  text-decoration: none;
  background-color: #e8ecf1;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.65625rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-primary .page-item.active .page-link, .pagination-primary .pagination .page-item.active .page-link {
  background: #8670f2 !important;
  border-color: #8670f2 !important;
}

.pagination-secondary .page-item.active .page-link, .pagination-secondary .pagination .page-item.active .page-link {
  background: #868e96 !important;
  border-color: #868e96 !important;
}

.pagination-success .page-item.active .page-link, .pagination-success .pagination .page-item.active .page-link {
  background: #1cbd35 !important;
  border-color: #1cbd35 !important;
}

.pagination-info .page-item.active .page-link, .pagination-info .pagination .page-item.active .page-link {
  background: #3a92ff !important;
  border-color: #3a92ff !important;
}

.pagination-warning .page-item.active .page-link, .pagination-warning .pagination .page-item.active .page-link {
  background: #fd780a !important;
  border-color: #fd780a !important;
}

.pagination-danger .page-item.active .page-link, .pagination-danger .pagination .page-item.active .page-link {
  background: #eb4c5d !important;
  border-color: #eb4c5d !important;
}

.pagination-light .page-item.active .page-link, .pagination-light .pagination .page-item.active .page-link {
  background: #f3f6fa !important;
  border-color: #f3f6fa !important;
}

.pagination-dark .page-item.active .page-link, .pagination-dark .pagination .page-item.active .page-link {
  background: #525f6c !important;
  border-color: #525f6c !important;
}

.pagination-teal .page-item.active .page-link, .pagination-teal .pagination .page-item.active .page-link {
  background: #26c1b2 !important;
  border-color: #26c1b2 !important;
}

.pagination-indigo .page-item.active .page-link, .pagination-indigo .pagination .page-item.active .page-link {
  background: #6610f2 !important;
  border-color: #6610f2 !important;
}

.pagination-cyan .page-item.active .page-link, .pagination-cyan .pagination .page-item.active .page-link {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.pagination-yellow .page-item.active .page-link, .pagination-yellow .pagination .page-item.active .page-link {
  background: #ffc411 !important;
  border-color: #ffc411 !important;
}

.pagination-pink .page-item.active .page-link, .pagination-pink .pagination .page-item.active .page-link {
  background: #e83e8c !important;
  border-color: #e83e8c !important;
}

/* Badge */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #8670f2;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #5f42ed;
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: #fff;
  background-color: #1cbd35;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #159129;
}

.badge-info {
  color: #fff;
  background-color: #3a92ff;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0776ff;
}

.badge-warning {
  color: #fff;
  background-color: #fd780a;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #d26002;
}

.badge-danger {
  color: #fff;
  background-color: #eb4c5d;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #e61e33;
}

.badge-light {
  color: #111;
  background-color: #f3f6fa;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #cfdbeb;
}

.badge-dark {
  color: #fff;
  background-color: #525f6c;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3c464f;
}

.badge-teal {
  color: #fff;
  background-color: #26c1b2;
}

.badge-teal[href]:focus, .badge-teal[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e968b;
}

.badge-indigo {
  color: #fff;
  background-color: #6610f2;
}

.badge-indigo[href]:focus, .badge-indigo[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #510bc4;
}

.badge-cyan {
  color: #fff;
  background-color: #17a2b8;
}

.badge-cyan[href]:focus, .badge-cyan[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-yellow {
  color: #111;
  background-color: #ffc411;
}

.badge-yellow[href]:focus, .badge-yellow[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #dda600;
}

.badge-pink {
  color: #fff;
  background-color: #e83e8c;
}

.badge-pink[href]:focus, .badge-pink[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #d91a72;
}

/* Jumbotron */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e8ecf1;
  border-radius: 2px;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

/* Alert */
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 2px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #463a7e;
  background-color: #e7e2fc;
  border-color: #ddd7fb;
}

.alert-primary hr {
  border-top-color: #c9c0f9;
}

.alert-primary .alert-link {
  color: #332a5b;
}

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}

.alert-secondary hr {
  border-top-color: #cfd2d6;
}

.alert-secondary .alert-link {
  color: #2e3133;
}

.alert-success {
  color: #0f621c;
  background-color: #d2f2d7;
  border-color: #bfedc6;
}

.alert-success hr {
  border-top-color: #abe7b4;
}

.alert-success .alert-link {
  color: #08360f;
}

.alert-info {
  color: #1e4c85;
  background-color: #d8e9ff;
  border-color: #c8e0ff;
}

.alert-info hr {
  border-top-color: #afd2ff;
}

.alert-info .alert-link {
  color: #15345b;
}

.alert-warning {
  color: #843e05;
  background-color: #ffe4ce;
  border-color: #fed9ba;
}

.alert-warning hr {
  border-top-color: #fecba1;
}

.alert-warning .alert-link {
  color: #532703;
}

.alert-danger {
  color: #7a2830;
  background-color: #fbdbdf;
  border-color: #f9cdd2;
}

.alert-danger hr {
  border-top-color: #f6b6be;
}

.alert-danger .alert-link {
  color: #541b21;
}

.alert-light {
  color: #7e8082;
  background-color: #fdfdfe;
  border-color: #fcfcfe;
}

.alert-light hr {
  border-top-color: #e9e9f8;
}

.alert-light .alert-link {
  color: #656768;
}

.alert-dark {
  color: #2b3138;
  background-color: #dcdfe2;
  border-color: #cfd2d6;
}

.alert-dark hr {
  border-top-color: #c1c5ca;
}

.alert-dark .alert-link {
  color: #15181b;
}

.alert-teal {
  color: #14645d;
  background-color: #d4f3f0;
  border-color: #c2eee9;
}

.alert-teal hr {
  border-top-color: #aee8e2;
}

.alert-teal .alert-link {
  color: #0c3a35;
}

.alert-indigo {
  color: #35087e;
  background-color: #e0cffc;
  border-color: #d4bcfb;
}

.alert-indigo hr {
  border-top-color: #c5a4fa;
}

.alert-indigo .alert-link {
  color: #21054e;
}

.alert-cyan {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-cyan hr {
  border-top-color: #abdde5;
}

.alert-cyan .alert-link {
  color: #062c33;
}

.alert-yellow {
  color: #856609;
  background-color: #fff3cf;
  border-color: #ffeebc;
}

.alert-yellow hr {
  border-top-color: #ffe8a3;
}

.alert-yellow .alert-link {
  color: #554106;
}

.alert-pink {
  color: #792049;
  background-color: #fad8e8;
  border-color: #f9c9df;
}

.alert-pink hr {
  border-top-color: #f6b2d1;
}

.alert-pink .alert-link {
  color: #511531;
}

/* Progress */
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e8ecf1;
  border-radius: 2px;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #8670f2;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.progress-thin, .progress-sm {
  height: 4px;
  border-radius: 0;
}

.progress-xs {
  height: 3px;
  border-radius: 0;
}

.progress-vertical {
  display: flex;
  flex-direction: column !important;
  justify-content: end !important;
  width: 1rem;
  height: 15rem;
}

.progress-vertical.progress-thin {
  width: 5px;
  height: 15rem;
}

.progress-vertical .progress-bar {
  width: 100%;
}

/* Media */
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-box {
  width: 3.15rem;
  height: 3.15rem;
  border-radius: 2px;
  line-height: 3.15rem;
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
}

.media-box-sm {
  width: 2.75rem;
  height: 2.75rem;
  line-height: 2.75rem;
  font-size: 0.9rem;
}

.media-box-xs {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  font-size: 0.75rem;
}

.image-with-text {
  position: relative;
}

.image-with-text .image-text {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 0.7rem;
  background: rgba(0, 0, 0, 0.2);
}

.img-thumb-sq {
  border-radius: 2px !important;
}

.img-thumb-sm {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.img-thumb {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
}

.img-thumb-xs {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
}

.img-thumb-lg {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}

.img-thumb-xl {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
}

.img-thumb-xxl {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.img-thumb-xlg {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.pull-up-lg {
  margin-top: -4rem;
}

.list-group-linked {
  border: 1px solid #f3f6fa;
  margin: 0;
  padding: 0;
}

.list-group-linked .list-group-item {
  border-bottom: 1px solid #f3f6fa;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.list-group-linked .list-group-item a {
  padding: 1rem;
  cursor: pointer;
}

.list-group-linked .list-group-item a:hover {
  text-decoration: none;
  background-color: #f3f6fa;
}

.list-group-linked .list-group-item:last-child {
  border-bottom: 0;
}

/* List Group */
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #525f6c;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #525f6c;
  text-decoration: none;
  background-color: #f3f6fa;
}

.list-group-item-action:active {
  color: #576472;
  background-color: #e8ecf1;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #8670f2;
  border-color: #8670f2;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #463a7e;
  background-color: #ddd7fb;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #463a7e;
}

a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #463a7e;
  background-color: #c9c0f9;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #463a7e;
  border-color: #463a7e;
}

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e;
}

a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.list-group-item-success {
  color: #0f621c;
  background-color: #bfedc6;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #0f621c;
}

a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #0f621c;
  background-color: #abe7b4;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #0f621c;
  border-color: #0f621c;
}

.list-group-item-info {
  color: #1e4c85;
  background-color: #c8e0ff;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #1e4c85;
}

a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #1e4c85;
  background-color: #afd2ff;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #1e4c85;
  border-color: #1e4c85;
}

.list-group-item-warning {
  color: #843e05;
  background-color: #fed9ba;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #843e05;
}

a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #843e05;
  background-color: #fecba1;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #843e05;
  border-color: #843e05;
}

.list-group-item-danger {
  color: #7a2830;
  background-color: #f9cdd2;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #7a2830;
}

a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #7a2830;
  background-color: #f6b6be;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #7a2830;
  border-color: #7a2830;
}

.list-group-item-light {
  color: #7e8082;
  background-color: #fcfcfe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #7e8082;
}

a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #7e8082;
  background-color: #e9e9f8;
}

a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #7e8082;
  border-color: #7e8082;
}

.list-group-item-dark {
  color: #2b3138;
  background-color: #cfd2d6;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #2b3138;
}

a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #2b3138;
  background-color: #c1c5ca;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #2b3138;
  border-color: #2b3138;
}

.list-group-item-teal {
  color: #14645d;
  background-color: #c2eee9;
}

a.list-group-item-teal,
button.list-group-item-teal {
  color: #14645d;
}

a.list-group-item-teal:focus, a.list-group-item-teal:hover,
button.list-group-item-teal:focus,
button.list-group-item-teal:hover {
  color: #14645d;
  background-color: #aee8e2;
}

a.list-group-item-teal.active,
button.list-group-item-teal.active {
  color: #fff;
  background-color: #14645d;
  border-color: #14645d;
}

.list-group-item-indigo {
  color: #35087e;
  background-color: #d4bcfb;
}

a.list-group-item-indigo,
button.list-group-item-indigo {
  color: #35087e;
}

a.list-group-item-indigo:focus, a.list-group-item-indigo:hover,
button.list-group-item-indigo:focus,
button.list-group-item-indigo:hover {
  color: #35087e;
  background-color: #c5a4fa;
}

a.list-group-item-indigo.active,
button.list-group-item-indigo.active {
  color: #fff;
  background-color: #35087e;
  border-color: #35087e;
}

.list-group-item-cyan {
  color: #0c5460;
  background-color: #bee5eb;
}

a.list-group-item-cyan,
button.list-group-item-cyan {
  color: #0c5460;
}

a.list-group-item-cyan:focus, a.list-group-item-cyan:hover,
button.list-group-item-cyan:focus,
button.list-group-item-cyan:hover {
  color: #0c5460;
  background-color: #abdde5;
}

a.list-group-item-cyan.active,
button.list-group-item-cyan.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-yellow {
  color: #856609;
  background-color: #ffeebc;
}

a.list-group-item-yellow,
button.list-group-item-yellow {
  color: #856609;
}

a.list-group-item-yellow:focus, a.list-group-item-yellow:hover,
button.list-group-item-yellow:focus,
button.list-group-item-yellow:hover {
  color: #856609;
  background-color: #ffe8a3;
}

a.list-group-item-yellow.active,
button.list-group-item-yellow.active {
  color: #fff;
  background-color: #856609;
  border-color: #856609;
}

.list-group-item-pink {
  color: #792049;
  background-color: #f9c9df;
}

a.list-group-item-pink,
button.list-group-item-pink {
  color: #792049;
}

a.list-group-item-pink:focus, a.list-group-item-pink:hover,
button.list-group-item-pink:focus,
button.list-group-item-pink:hover {
  color: #792049;
  background-color: #f6b2d1;
}

a.list-group-item-pink.active,
button.list-group-item-pink.active {
  color: #fff;
  background-color: #792049;
  border-color: #792049;
}

.list-group.list-group-sm .list-group-item {
  padding: 0.3rem 0.75rem;
}

.list-group.list-group-borderless .list-group-item {
  border: none;
}

.block-list {
  display: block;
  margin: 0;
  padding: 0;
}

.block-list > li {
  display: block;
  margin: 0;
  padding: 0;
}

/* Close Button */
.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5;
}

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

/* Modal */
.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e8ecf1;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.modal-header .close {
  padding: 15px;
  margin: -15px -15px -15px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e8ecf1;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

/* Tooltip */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.65625rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}

.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
}

.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
}

.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.75rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 2px;
}

.tooltip {
  font-size: 0.85rem !important;
}

/* Popover */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.65625rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow::before {
  content: "";
  border-width: 0.8rem;
}

.popover .arrow::after {
  content: "";
  border-width: 0.8rem;
}

.popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.8rem;
}

.popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-bottom-width: 0;
}

.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: -0.8rem;
  margin-left: -0.8rem;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: calc((0.8rem - 1px) * -1);
  margin-left: -0.8rem;
  border-top-color: #ffffff;
}

.popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.8rem;
}

.popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
  left: 0;
}

.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  margin-top: -0.8rem;
  border-left-width: 0;
}

.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: -0.8rem;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: calc((0.8rem - 1px) * -1);
  border-right-color: #ffffff;
}

.popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.8rem;
}

.popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  margin-left: -0.8rem;
  border-top-width: 0;
}

.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: -0.8rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: calc((0.8rem - 1px) * -1);
  border-bottom-color: #ffffff;
}

.popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.8rem;
}

.popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
  right: 0;
}

.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  margin-top: -0.8rem;
  border-right-width: 0;
}

.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: -0.8rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: calc((0.8rem - 1px) * -1);
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(2px - 1px);
  border-top-right-radius: calc(2px - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #576472;
}

/* Carousel */
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #ffffff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.carousel-light .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-light .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ced4da' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-light .carousel-indicators li {
  background-color: #ced4da;
}

.carousel-light .carousel-indicators li.active {
  background-color: #868e96;
}

.carousel-slim .carousel-control-prev, .carousel-slim .carousel-control-next {
  width: 2rem;
}

.carousel-slim .carousel-indicators {
  margin-bottom: 0;
}

.carousel-item, .carousel-inner, .carousel, .slide {
  outline: 0;
}

.carousel-item.active, .carousel-item:active, .carousel-item:focus, .carousel-inner.active, .carousel-inner:active, .carousel-inner:focus, .carousel.active, .carousel:active, .carousel:focus, .slide.active, .slide:active, .slide:focus {
  outline: 0;
}

/* Utilites */
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #8670f2 !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #5f42ed !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #1cbd35 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #159129 !important;
}

.bg-info {
  background-color: #3a92ff !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #0776ff !important;
}

.bg-warning {
  background-color: #fd780a !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d26002 !important;
}

.bg-danger {
  background-color: #eb4c5d !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #e61e33 !important;
}

.bg-light {
  background-color: #f3f6fa !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #cfdbeb !important;
}

.bg-dark {
  background-color: #525f6c !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #3c464f !important;
}

.bg-teal {
  background-color: #26c1b2 !important;
}

a.bg-teal:focus, a.bg-teal:hover {
  background-color: #1e968b !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:focus, a.bg-indigo:hover {
  background-color: #510bc4 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:focus, a.bg-cyan:hover {
  background-color: #117a8b !important;
}

.bg-yellow {
  background-color: #ffc411 !important;
}

a.bg-yellow:focus, a.bg-yellow:hover {
  background-color: #dda600 !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:focus, a.bg-pink:hover {
  background-color: #d91a72 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e8ecf1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #8670f2 !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #1cbd35 !important;
}

.border-info {
  border-color: #3a92ff !important;
}

.border-warning {
  border-color: #fd780a !important;
}

.border-danger {
  border-color: #eb4c5d !important;
}

.border-light {
  border-color: #f3f6fa !important;
}

.border-dark {
  border-color: #525f6c !important;
}

.border-teal {
  border-color: #26c1b2 !important;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.border-cyan {
  border-color: #17a2b8 !important;
}

.border-yellow {
  border-color: #ffc411 !important;
}

.border-pink {
  border-color: #e83e8c !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded {
  border-radius: 2px !important;
}

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.25rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7,
.my-7 {
  margin-top: 1.75rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 1.75rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8,
.my-8 {
  margin-top: 2rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 2rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9,
.my-9 {
  margin-top: 2.25rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 2.25rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 2.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 2.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11,
.my-11 {
  margin-top: 2.75rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 2.75rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12,
.my-12 {
  margin-top: 3rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 3rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 3rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 3rem !important;
}

.m-13 {
  margin: 3.5rem !important;
}

.mt-13,
.my-13 {
  margin-top: 3.5rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 3.5rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 3.5rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 3.5rem !important;
}

.m-14 {
  margin: 4rem !important;
}

.mt-14,
.my-14 {
  margin-top: 4rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 4rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 4rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 4rem !important;
}

.m-15 {
  margin: 5rem !important;
}

.mt-15,
.my-15 {
  margin-top: 5rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 5rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 5rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 5rem !important;
}

.m-16 {
  margin: 6rem !important;
}

.mt-16,
.my-16 {
  margin-top: 6rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 6rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 6rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.25rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.25rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 1.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.pt-7,
.py-7 {
  padding-top: 1.75rem !important;
}

.pr-7,
.px-7 {
  padding-right: 1.75rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7,
.px-7 {
  padding-left: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8,
.py-8 {
  padding-top: 2rem !important;
}

.pr-8,
.px-8 {
  padding-right: 2rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 2rem !important;
}

.pl-8,
.px-8 {
  padding-left: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.pt-9,
.py-9 {
  padding-top: 2.25rem !important;
}

.pr-9,
.px-9 {
  padding-right: 2.25rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 2.25rem !important;
}

.pl-9,
.px-9 {
  padding-left: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 2.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.pt-11,
.py-11 {
  padding-top: 2.75rem !important;
}

.pr-11,
.px-11 {
  padding-right: 2.75rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 2.75rem !important;
}

.pl-11,
.px-11 {
  padding-left: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12,
.py-12 {
  padding-top: 3rem !important;
}

.pr-12,
.px-12 {
  padding-right: 3rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 3rem !important;
}

.pl-12,
.px-12 {
  padding-left: 3rem !important;
}

.p-13 {
  padding: 3.5rem !important;
}

.pt-13,
.py-13 {
  padding-top: 3.5rem !important;
}

.pr-13,
.px-13 {
  padding-right: 3.5rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 3.5rem !important;
}

.pl-13,
.px-13 {
  padding-left: 3.5rem !important;
}

.p-14 {
  padding: 4rem !important;
}

.pt-14,
.py-14 {
  padding-top: 4rem !important;
}

.pr-14,
.px-14 {
  padding-right: 4rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 4rem !important;
}

.pl-14,
.px-14 {
  padding-left: 4rem !important;
}

.p-15 {
  padding: 5rem !important;
}

.pt-15,
.py-15 {
  padding-top: 5rem !important;
}

.pr-15,
.px-15 {
  padding-right: 5rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 5rem !important;
}

.pl-15,
.px-15 {
  padding-left: 5rem !important;
}

.p-16 {
  padding: 6rem !important;
}

.pt-16,
.py-16 {
  padding-top: 6rem !important;
}

.pr-16,
.px-16 {
  padding-right: 6rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 6rem !important;
}

.pl-16,
.px-16 {
  padding-left: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 1.75rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 2rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 2rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.25rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 2.25rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 2.5rem !important;
  }
  .m-sm-11 {
    margin: 2.75rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 2.75rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 2.75rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 2.75rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 3rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 3rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 3rem !important;
  }
  .m-sm-13 {
    margin: 3.5rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 3.5rem !important;
  }
  .m-sm-14 {
    margin: 4rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 4rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 4rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 4rem !important;
  }
  .m-sm-15 {
    margin: 5rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 5rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 5rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 5rem !important;
  }
  .m-sm-16 {
    margin: 6rem !important;
  }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 6rem !important;
  }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 6rem !important;
  }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 2rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 2rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.25rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 2.25rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 2.5rem !important;
  }
  .p-sm-11 {
    padding: 2.75rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 2.75rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 2.75rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 2.75rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 3rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 3rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 3rem !important;
  }
  .p-sm-13 {
    padding: 3.5rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 3.5rem !important;
  }
  .p-sm-14 {
    padding: 4rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 4rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 4rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 4rem !important;
  }
  .p-sm-15 {
    padding: 5rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 5rem !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 5rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 5rem !important;
  }
  .p-sm-16 {
    padding: 6rem !important;
  }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 6rem !important;
  }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 6rem !important;
  }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.25rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.25rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 1.75rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 1.75rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 2rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 2rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 2rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 2rem !important;
  }
  .m-md-9 {
    margin: 2.25rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 2.25rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 2.25rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 2.25rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 2.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 2.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 2.5rem !important;
  }
  .m-md-11 {
    margin: 2.75rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 2.75rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 2.75rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 2.75rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 3rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 3rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 3rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 3rem !important;
  }
  .m-md-13 {
    margin: 3.5rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 3.5rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 3.5rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 3.5rem !important;
  }
  .m-md-14 {
    margin: 4rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 4rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 4rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 4rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 4rem !important;
  }
  .m-md-15 {
    margin: 5rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 5rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 5rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 5rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 5rem !important;
  }
  .m-md-16 {
    margin: 6rem !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 6rem !important;
  }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 6rem !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 6rem !important;
  }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.25rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.25rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 1.75rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 1.75rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 2rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 2rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 2rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 2rem !important;
  }
  .p-md-9 {
    padding: 2.25rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 2.25rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 2.25rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 2.25rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 2.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 2.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 2.5rem !important;
  }
  .p-md-11 {
    padding: 2.75rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 2.75rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 2.75rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 2.75rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 3rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 3rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 3rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 3rem !important;
  }
  .p-md-13 {
    padding: 3.5rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 3.5rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 3.5rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 3.5rem !important;
  }
  .p-md-14 {
    padding: 4rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 4rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 4rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 4rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 4rem !important;
  }
  .p-md-15 {
    padding: 5rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 5rem !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 5rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 5rem !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 5rem !important;
  }
  .p-md-16 {
    padding: 6rem !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 6rem !important;
  }
  .pr-md-16,
  .px-md-16 {
    padding-right: 6rem !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 6rem !important;
  }
  .pl-md-16,
  .px-md-16 {
    padding-left: 6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 1.75rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 2rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 2rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.25rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 2.25rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 2.5rem !important;
  }
  .m-lg-11 {
    margin: 2.75rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 2.75rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 2.75rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 2.75rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 3rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 3rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 3rem !important;
  }
  .m-lg-13 {
    margin: 3.5rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 3.5rem !important;
  }
  .m-lg-14 {
    margin: 4rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 4rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 4rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 4rem !important;
  }
  .m-lg-15 {
    margin: 5rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 5rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 5rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 5rem !important;
  }
  .m-lg-16 {
    margin: 6rem !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 6rem !important;
  }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 6rem !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 2rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 2rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.25rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 2.25rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 2.5rem !important;
  }
  .p-lg-11 {
    padding: 2.75rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 2.75rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 2.75rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 2.75rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 3rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 3rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 3rem !important;
  }
  .p-lg-13 {
    padding: 3.5rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 3.5rem !important;
  }
  .p-lg-14 {
    padding: 4rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 4rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 4rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 4rem !important;
  }
  .p-lg-15 {
    padding: 5rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 5rem !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 5rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 5rem !important;
  }
  .p-lg-16 {
    padding: 6rem !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 6rem !important;
  }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 6rem !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 1.75rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 2rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 2rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.25rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 2.25rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 2.5rem !important;
  }
  .m-xl-11 {
    margin: 2.75rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 2.75rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 2.75rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 2.75rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 3rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 3rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 3rem !important;
  }
  .m-xl-13 {
    margin: 3.5rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 3.5rem !important;
  }
  .m-xl-14 {
    margin: 4rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 4rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 4rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 4rem !important;
  }
  .m-xl-15 {
    margin: 5rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 5rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 5rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 5rem !important;
  }
  .m-xl-16 {
    margin: 6rem !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 6rem !important;
  }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 6rem !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 2rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 2rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.25rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 2.25rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 2.5rem !important;
  }
  .p-xl-11 {
    padding: 2.75rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 2.75rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 2.75rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 2.75rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 3rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 3rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 3rem !important;
  }
  .p-xl-13 {
    padding: 3.5rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 3.5rem !important;
  }
  .p-xl-14 {
    padding: 4rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 4rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 4rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 4rem !important;
  }
  .p-xl-15 {
    padding: 5rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 5rem !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 5rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 5rem !important;
  }
  .p-xl-16 {
    padding: 6rem !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 6rem !important;
  }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 6rem !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #8670f2 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #5f42ed !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #1cbd35 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #159129 !important;
}

.text-info {
  color: #3a92ff !important;
}

a.text-info:focus, a.text-info:hover {
  color: #0776ff !important;
}

.text-warning {
  color: #fd780a !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d26002 !important;
}

.text-danger {
  color: #eb4c5d !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #e61e33 !important;
}

.text-light {
  color: #f3f6fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #cfdbeb !important;
}

.text-dark {
  color: #525f6c !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #3c464f !important;
}

.text-teal {
  color: #26c1b2 !important;
}

a.text-teal:focus, a.text-teal:hover {
  color: #1e968b !important;
}

.text-indigo {
  color: #6610f2 !important;
}

a.text-indigo:focus, a.text-indigo:hover {
  color: #510bc4 !important;
}

.text-cyan {
  color: #17a2b8 !important;
}

a.text-cyan:focus, a.text-cyan:hover {
  color: #117a8b !important;
}

.text-yellow {
  color: #ffc411 !important;
}

a.text-yellow:focus, a.text-yellow:hover {
  color: #dda600 !important;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:focus, a.text-pink:hover {
  color: #d91a72 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.border-top {
  border-top: 1px solid #e8ecf1;
}

.border-right {
  border-right: 1px solid #e8ecf1;
}

.border-bottom {
  border-bottom: 1px solid #e8ecf1;
}

.border-left {
  border-left: 1px solid #e8ecf1;
}

.flex-h-grid > * {
  border-right: 1px solid #e8ecf1;
  flex: 1;
}

.flex-h-grid > *:last-child {
  border-right: 0;
}

.text-size-0 {
  font-size: 0;
}

.text-size-1 {
  font-size: 0.25rem;
}

.text-size-2 {
  font-size: 0.5rem;
}

.text-size-3 {
  font-size: 0.75rem;
}

.text-size-4 {
  font-size: 1rem;
}

.text-size-5 {
  font-size: 1.25rem;
}

.text-size-6 {
  font-size: 1.5rem;
}

.text-size-7 {
  font-size: 1.75rem;
}

.text-size-8 {
  font-size: 2rem;
}

.text-size-9 {
  font-size: 2.25rem;
}

.text-size-10 {
  font-size: 2.5rem;
}

.text-size-11 {
  font-size: 2.75rem;
}

.text-size-12 {
  font-size: 3rem;
}

.text-size-13 {
  font-size: 3.5rem;
}

.text-size-14 {
  font-size: 4rem;
}

.text-size-15 {
  font-size: 5rem;
}

.text-size-16 {
  font-size: 6rem;
}

/* Icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../vendor/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../vendor/MaterialIcons-Regular.woff2) format("woff2"), url(../vendor/MaterialIcons-Regular.woff) format("woff"), url(../vendor/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.35em;
  display: inline-block;
  line-height: inherit;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.fa {
  font-size: 1.3em !important;
}

/* Layout */
/*
* Page Layout
* For the layout styles to work properly, each page must follow following page layout
* *******[Page Layout]*******
1. [HEAD]

    i. Title
    ii. Metadata
    iii. StyleSheets
    iv. Critical Javascript

2. [BODY]

    i. Full Page Wrapper Div (div.full-page)
        a. Left Sidebar (.sidebar-container div.sidebar.sidebar-left)

        b. Page WRapper(div.page)
          I. TopBar (.topbar-container .topbar)
          II. Main Page Division (div.page-content)
                  *** The Contents of Your Page Go Here****
          III. Right Sidebar (Optional) (.sidebar-right-container div.sidebar.sidebar-right)


    ii. JavaScript Files (for non Angular version)

*/
.sidebar-left {
  width: 17rem;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  transition: margin-left 0.3s, width 0.3s;
  overflow: hidden;
  z-index: 10;
  margin-left: -17rem;
}

.sidebar-left.sidebar-fixed {
  position: fixed;
}

.sidebar-left.sidebar-fixed .scroll {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.sidebar-left.visible {
  margin-left: 0;
}

@media (min-width: 1200px) {
  .sidebar-left {
    margin-left: 0;
  }
  .sidebar-left.visible {
    margin-left: 0;
  }
}

.full-page {
  position: relative;
  min-height: 99vh;
}

.sidebar-right {
  width: 17rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11;
  margin-right: -17rem;
  transition: margin-right 0.3s;
}

.sidebar-right.visible {
  margin-right: 0;
}

.page {
  margin-left: 0;
  position: relative;
  transition: 0.3s margin-left;
  padding-top: 3.8rem;
}

@media (min-width: 1200px) {
  .page {
    margin-left: 17rem;
  }
  .page.no-sidebar {
    margin-left: 4.5rem;
  }
}

.page-content {
  padding: 30px 15px;
  padding-bottom: 0;
  position: relative;
  padding-bottom: 30px;
}

.page-content .card {
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .page-content {
    padding: 30px 0;
  }
  .page-content .card {
    margin-bottom: 15px;
  }
}

/* Layout Utilities */
.display-hover-only-container .display-hover-only-item {
  visibility: hidden;
}

.display-hover-only-container:hover .display-hover-only-item {
  visibility: visible;
}

.ps__thumb-y {
  width: 3px !important;
}

.ps__rail-y:hover .ps__thumb-y {
  width: 9px !important;
}

/* Sidebar */
.sidebar {
  width: 17rem;
  background: white;
  color: inherit;
  min-height: 100vh;
  border-right: 1px solid #e8ecf1;
  box-shadow: 0 1px 1px 1px rgba(200, 200, 200, 0.1);
  z-index: 20;
}

.sidebar-dark {
  background: #384149;
  border-right: 0;
}

.sidebar-right {
  box-shadow: 0 1px 2px 2px rgba(100, 100, 100, 0.1);
}

.sidebar-brand {
  position: relative;
  height: 3.8rem;
  padding: 0.3rem 1.3rem;
  line-height: 3.2rem;
  font-size: 1.3em;
  font-family: 'Arvo';
  font-weight: 400;
}

.sidebar-brand .title {
  transition: opacity 0.5s;
}

.sidebar-header {
  font-weight: 400;
}

.sidebar-header .logo {
  top: 0.65rem;
  right: 1.25rem;
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebar-header .logo .material-icons {
  font-size: 1.4rem;
  line-height: 1;
}

@media (min-width: 1200px) {
  .sidebar.collapsed {
    width: 4.5rem;
  }
  .sidebar.collapsed .list-nav {
    width: 17rem;
  }
  .sidebar.collapsed .sidebar-brand .title {
    opacity: 0;
  }
  .sidebar.collapsed .list-nav .list-nav-label {
    display: none;
  }
  .sidebar.collapsed .list-nav .list-nav-group-title {
    visibility: hidden;
  }
  .sidebar.collapsed .list-nav .list-nav-group-title::after {
    content: '*';
    visibility: visible;
    position: absolute;
    right: 2rem;
  }
  .sidebar.collapsed .list-nav .list-nav-expand {
    display: none;
  }
  .sidebar.collapsed:hover {
    width: 17rem;
  }
  .sidebar.collapsed:hover .sidebar-brand .title {
    opacity: 1;
  }
  .sidebar.collapsed:hover .list-nav .list-nav-label {
    display: inline;
  }
  .sidebar.collapsed:hover .list-nav .list-nav-group-title {
    visibility: visible;
  }
  .sidebar.collapsed:hover .list-nav .list-nav-group-title::after {
    visibility: hidden;
  }
  .sidebar.collapsed:hover .list-nav .list-nav-expand {
    display: inline;
  }
}

/* Topbar */
.topbar {
  height: 3.8rem;
  z-index: 9;
  border-bottom: 1px solid #e8ecf1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  transition: left 0.3s;
}

.topbar .topbar-search {
  transition: width 0.3s, padding 0.3s, right 0.3s;
  width: 15rem;
  padding-right: 2.25rem;
  position: relative;
  right: -2.25rem;
}

.topbar .topbar-search.collapsed {
  width: 0;
  padding-left: 0;
  padding-right: 0;
  right: 0;
}

.topbar.fixed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  .topbar.fixed {
    left: 17rem;
  }
}

@media (max-width: 575px) {
  .topbar .navbar-nav {
    display: flex;
    flex-direction: row;
  }
  .topbar .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .topbar .navbar-nav .dropdown {
    position: initial !important;
  }
  .topbar .navbar-nav .dropdown-menu {
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .no-sidebar .topbar.fixed {
    position: fixed;
    left: 4.5rem;
    top: 0;
    right: 0;
    box-shadow: 0 1px 1px 1px rgba(100, 100, 100, 0.1);
  }
}

.navbar .nav-link {
  position: relative;
  color: inherit;
  display: inline-block;
  height: 3.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar .nav-link .fa, .navbar .nav-link .material-icons {
  font-size: 1.5em;
  bottom: 0;
}

.navbar .nav-link span.badge {
  float: right;
  position: relative;
  margin-left: -0.7rem;
  margin-top: -1.25rem;
  border-radius: 50%;
  font-size: 0.6rem;
  width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  padding: 0;
  text-align: center;
}

.dropdown-menu {
  padding: 0;
  border-color: #e8ecf1;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.dropdown-menu .dropdown-item {
  padding: 0.75rem 1rem;
  color: #525f6c;
}

.dropdown-menu .dropdown-item:hover {
  background: #f3f6fa;
}

/* Timeline Component */
.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  margin-left: 9px;
  margin-right: 2rem;
}

.timeline .timeline-line {
  display: block;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  padding-top: 18px;
  left: 0;
  bottom: 0;
}

.timeline .timeline-line:after {
  position: relative;
  display: block;
  height: 100%;
  border: 1px solid #dee2e6;
  width: 1px;
  content: " ";
}

.timeline-event-card {
  padding: 1rem 1rem;
}

.timeline-event {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-top: 0;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
}

.timeline-event:after {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  margin-top: 3px;
  content: " ";
  border-radius: 50%;
  background: #3a92ff;
  z-index: 2;
}

.timeline-event:before {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -9px;
  width: 18px;
  height: 18px;
  content: " ";
  border-radius: 50%;
  background: transparent;
  border: 2px solid #3a92ff;
  z-index: 1;
  box-sizing: border-box;
}

.timeline-event.single-dot:after {
  margin-top: 0;
  margin-left: -9px;
  width: 18px;
  height: 18px;
}

.timeline-event.single-dot:before {
  display: none;
}

.timeline-event.single-circle:after {
  display: none;
}

.timeline-lg .timeline-line {
  padding-top: 24px;
}

.timeline-lg .timeline-event:after {
  margin-left: -9px;
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.timeline-lg .timeline-event:before {
  margin-left: -12px;
  width: 24px;
  height: 24px;
}

.timeline-lg .timeline-event.single-dot:after {
  margin-left: -12px;
  width: 24px;
  height: 24px;
}

.timeline-event-primary:after {
  background: #8670f2;
}

.timeline-event-primary:before {
  border: 2px solid #8670f2;
}

.timeline-event-secondary:after {
  background: #868e96;
}

.timeline-event-secondary:before {
  border: 2px solid #868e96;
}

.timeline-event-success:after {
  background: #1cbd35;
}

.timeline-event-success:before {
  border: 2px solid #1cbd35;
}

.timeline-event-info:after {
  background: #3a92ff;
}

.timeline-event-info:before {
  border: 2px solid #3a92ff;
}

.timeline-event-warning:after {
  background: #fd780a;
}

.timeline-event-warning:before {
  border: 2px solid #fd780a;
}

.timeline-event-danger:after {
  background: #eb4c5d;
}

.timeline-event-danger:before {
  border: 2px solid #eb4c5d;
}

.timeline-event-light:after {
  background: #f3f6fa;
}

.timeline-event-light:before {
  border: 2px solid #f3f6fa;
}

.timeline-event-dark:after {
  background: #525f6c;
}

.timeline-event-dark:before {
  border: 2px solid #525f6c;
}

.timeline-event-teal:after {
  background: #26c1b2;
}

.timeline-event-teal:before {
  border: 2px solid #26c1b2;
}

.timeline-event-indigo:after {
  background: #6610f2;
}

.timeline-event-indigo:before {
  border: 2px solid #6610f2;
}

.timeline-event-cyan:after {
  background: #17a2b8;
}

.timeline-event-cyan:before {
  border: 2px solid #17a2b8;
}

.timeline-event-yellow:after {
  background: #ffc411;
}

.timeline-event-yellow:before {
  border: 2px solid #ffc411;
}

.timeline-event-pink:after {
  background: #e83e8c;
}

.timeline-event-pink:before {
  border: 2px solid #e83e8c;
}

/* Charts */
.chart-container {
  position: relative;
}

/* Import Chart Styles */
.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central;
}

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: rgba(50, 50, 50, 0.9);
  stroke-width: 0.2px;
  stroke-dasharray: 0px;
}

.ct-grid-background {
  fill: none;
}

.ct-point {
  stroke-width: 6px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 2px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}

.ct-bar {
  fill: none;
  stroke-width: 30px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: rgba(134, 112, 242, 0.9);
}

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: rgba(134, 112, 242, 0.9);
}

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: rgba(38, 193, 178, 0.9);
}

.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: rgba(38, 193, 178, 0.9);
}

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: rgba(28, 189, 53, 0.9);
}

.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: rgba(28, 189, 53, 0.9);
}

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: rgba(58, 146, 255, 0.9);
}

.ct-series-d .ct-slice-pie, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-area {
  fill: rgba(58, 146, 255, 0.9);
}

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: rgba(253, 120, 10, 0.9);
}

.ct-series-e .ct-slice-pie, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-area {
  fill: rgba(253, 120, 10, 0.9);
}

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: rgba(235, 76, 93, 0.9);
}

.ct-series-f .ct-slice-pie, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-area {
  fill: rgba(235, 76, 93, 0.9);
}

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: rgba(23, 162, 184, 0.9);
}

.ct-series-g .ct-slice-pie, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-area {
  fill: rgba(23, 162, 184, 0.9);
}

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: rgba(102, 16, 242, 0.9);
}

.ct-series-h .ct-slice-pie, .ct-series-h .ct-slice-donut-solid, .ct-series-h .ct-area {
  fill: rgba(102, 16, 242, 0.9);
}

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: rgba(255, 196, 17, 0.9);
}

.ct-series-i .ct-slice-pie, .ct-series-i .ct-slice-donut-solid, .ct-series-i .ct-area {
  fill: rgba(255, 196, 17, 0.9);
}

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: rgba(232, 62, 140, 0.9);
}

.ct-series-j .ct-slice-pie, .ct-series-j .ct-slice-donut-solid, .ct-series-j .ct-area {
  fill: rgba(232, 62, 140, 0.9);
}

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #dda458;
}

.ct-series-k .ct-slice-pie, .ct-series-k .ct-slice-donut-solid, .ct-series-k .ct-area {
  fill: #dda458;
}

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #eacf7d;
}

.ct-series-l .ct-slice-pie, .ct-series-l .ct-slice-donut-solid, .ct-series-l .ct-area {
  fill: #eacf7d;
}

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #86797d;
}

.ct-series-m .ct-slice-pie, .ct-series-m .ct-slice-donut-solid, .ct-series-m .ct-area {
  fill: #86797d;
}

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #b2c326;
}

.ct-series-n .ct-slice-pie, .ct-series-n .ct-slice-donut-solid, .ct-series-n .ct-area {
  fill: #b2c326;
}

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #6188e2;
}

.ct-series-o .ct-slice-pie, .ct-series-o .ct-slice-donut-solid, .ct-series-o .ct-area {
  fill: #6188e2;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}

.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%;
}

.ct-square:after {
  content: "";
  display: table;
  clear: both;
}

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}

.ct-minor-second:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.88889%;
}

.ct-major-second:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.33333%;
}

.ct-minor-third:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%;
}

.ct-major-third:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%;
}

.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.66667%;
}

.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}

.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.8047%;
}

.ct-golden-section:after {
  content: "";
  display: table;
  clear: both;
}

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%;
}

.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.33333%;
}

.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%;
}

.ct-octave:after {
  content: "";
  display: table;
  clear: both;
}

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%;
}

.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.33333%;
}

.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%;
}

.ct-double-octave:after {
  content: "";
  display: table;
  clear: both;
}

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.c3 path, .c3 line {
  fill: none;
  stroke: #000;
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3;
}

/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 1px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: .1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: .1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9;
}

.c3-tooltip tr {
  border: 1px solid #CCC;
}

.c3-tooltip th {
  background-color: #aaa;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #FFF;
}

.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

.c3 path {
  stroke: #dee2e6;
}

.c3-axis-x .tick {
  color: #adb5bd;
}

.c3-axis-x .tick line {
  visibility: hidden;
}

.c3-axis-x-label {
  stroke: red;
}

.c3-axis-y .tick {
  color: #adb5bd;
}

.c3-axis-y .tick line {
  visibility: hidden;
}

.ct-label {
  fill: white;
}

.js-plotly-plot .plotly .modebar {
  z-index: 8 !important;
}

/* Calendar */
.fc-toolbar.fc-header-toolbar {
  padding: 1rem 1.5rem;
  padding-top: 6rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.fc-toolbar.fc-header-toolbar button {
  background: #3a92ff;
  color: #fff;
  border: none;
  padding: 0.5rem 0.75rem;
  height: 2.8em;
  line-height: 1;
  border-radius: 0px;
  text-transform: capitalize;
  text-shadow: none;
}

.fc-toolbar.fc-header-toolbar button:hover {
  background: #2184ff;
}

.fc-toolbar.fc-header-toolbar .fc-left, .fc-toolbar.fc-header-toolbar .fc-right {
  float: none;
}

.fc-toolbar.fc-header-toolbar .fc-left {
  order: 0;
}

.fc-toolbar.fc-header-toolbar .fc-center {
  order: 1;
}

.fc-toolbar.fc-header-toolbar .fc-right {
  order: 2;
}

.fc-left h2 {
  font-size: 1.5em;
}

table .fc-head .fc-widget-header td, table .fc-head .fc-widget-header th, table .fc-head .fc-widget-content td, table .fc-head .fc-widget-content th, table .fc-body .fc-widget-header td, table .fc-body .fc-widget-header th, table .fc-body .fc-widget-content td, table .fc-body .fc-widget-content th {
  padding: 0.5rem 0.1rem;
}

.fc-widget-content .fc-day-grid .fc-day-number {
  padding: 0.75rem;
}

.fc-month-view > table {
  border-left: none !important;
}

.fc-month-view > table > thead > tr > td, .fc-month-view > table > tbody > tr > td {
  border-left: none !important;
  border-right: none !important;
}

.fc-event-container {
  padding: 0 !important;
}

.external-events .fc-event {
  margin-bottom: 0.5em;
}

.fc-event, .fc-event-dot {
  border-radius: 0;
  padding: 0.25rem;
  background-color: #3a92ff;
  color: white !important;
}

.fc-scroller {
  height: auto !important;
}

@media (max-width: 767px) {
  body .fc {
    font-size: 0.9em !important;
  }
  body .fc .fc-toolbar.fc-header-toolbar {
    position: relative;
    padding-top: 4rem;
  }
  body .fc .fc-toolbar.fc-header-toolbar button {
    padding: 0.5rem 0.5rem;
    font-size: 0.9em !important;
  }
  .fc-left h2 {
    font-size: 1.2em;
    font-weight: 500;
  }
  .fc-right {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .fc-widget-content .fc-day-grid .fc-day-number {
    padding: 0 0.25rem;
    font-size: 0.9em;
  }
}

/* Email */
.email-sidebar {
  width: 17rem;
  min-width: 17rem;
  min-height: 95vh;
  border-right: 1px solid #eee;
}

@media (max-width: 991px) {
  .email-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: white;
    display: none;
  }
  .email-sidebar.expanded {
    display: block;
  }
}

.email-container {
  position: relative;
}

.email-card {
  overflow: auto;
}

.email-toggle {
  display: none;
}

@media (max-width: 991px) {
  .email-toggle {
    display: block;
  }
}

.email-list {
  min-width: 25rem;
  overflow-x: auto;
}

.email-list table td:first-child {
  white-space: nowrap;
}

.bg-gradient {
  background-color: #313446;
  color: white !important;
  background-image: url("../assets/img/bg-8.png");
  background-repeat: no-repeat;
  background-size: 116rem;
  background-position: top;
}

.font-fancy {
  font-family: Cookie, cursive;
  font-size: 2rem !important;
}

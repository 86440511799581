

@mixin responsive-table-generate() {
  thead,tbody {
    tr {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $gray-200;
      td,th {
        border: none;
        padding: 0.75rem 0.75rem 0.75rem 1.5em;
        &.rt-full {
          flex-basis: 100%;
          flex-shrink: 0;
          padding: 0.75rem 1.5rem 0.75rem 1.5rem;
        }
        &.rt-header {
          font-weight: 500;
        }
        &:first-child {
          padding-left: 1.5rem;
        }
        &:last-child {
          padding-right: 1.5rem;
          margin-bottom: 0.75rem;
        }
      }
    }
  }
  .rt-table-hide {
    display: none !important;
  }
  .rt-table-block {
    display: block !important;
  }
  .rt-table-inline {
    display: inline !important;
  }
  .progress {
    width: 100%;
  }
}

@mixin button-flat-variant($color, $bgcolor-hover: $gray-100) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: none;

  @include hover {
    background-color: $bgcolor-hover;
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {

    background-color: $bgcolor-hover;
    box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .5);
  }
  &.no-shadow {
    @include hover {
      box-shadow: none;
    }
  }
}

.topbar {
  height: $topbar-size;
  z-index: 9;
  .nav-item {

  }
  border-bottom: 1px solid $gray-200;
  position: absolute;
  left: 0;
  top:0;
  right: 0;

  .topbar-search {
    transition: width 0.3s, padding 0.3s, right 0.3s;
    width: 15rem;
    padding-right: 2.25rem;
    &.collapsed {
      width: 0;
      padding-left: 0;
      padding-right: 0;
      right: 0;
    }

    position: relative;

    right: -2.25rem;
  }
  &.fixed {
    position: fixed;
    left: 0;
    @include media-breakpoint-sidebar-collapse() {
      left: $sidebar-size;
    }
    top:0;
    right: 0;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.1);
  }
  transition: left 0.3s;
  @include media-breakpoint-down(xs) {
    .navbar-nav {
      display: flex;
      flex-direction: row;
      .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      .dropdown {
        position: initial !important;
      }
      .dropdown-menu {
        width: 100% !important;
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;

      }
    }
  }


}
@include media-breakpoint-sidebar-collapse() {
.no-sidebar {
  .topbar {
    &.fixed {
      position: fixed;
      left: 4.5rem;
      top:0;
      right: 0;
      box-shadow: 0 1px 1px 1px rgba(100,100,100,0.1);
    }
  }
}
}
.navbar {
  .nav-link {
    position: relative;
    color: inherit;
    display: inline-block;
    height: $topbar-size;
    display: flex;
    align-items: center;
    cursor: pointer;
    .fa,.material-icons {
      font-size: 1.5em;
      bottom:0;
    }
    span.badge {
      float: right;
      position: relative;
      margin-left: -0.7rem;
      margin-top:-1.25rem;
      border-radius: 50%;
      font-size: 0.6rem;
      width: 1.1rem;
      height: 1.1rem;
      line-height: 1.1rem;
      padding: 0;
      text-align: center;
    }
  }
}


.dropdown-menu {
  padding: 0;
  border-color: $gray-200;
  box-shadow: 0 2px 2px 1px rgba(0,0,0,0.1);
  .dropdown-item {
    padding: 0.75rem 1rem;
    color: $gray-700;
    &:hover {
      background: $gray-100;
    }
  }

}

/**
*
*  Simplify Admin
*  v1.0
*  Created by geekman.site
*
*  Last Modified: 31 December 2017
*  See Manual for Full Changelog
*
*/

/*
* Simplify Admin Modifies and extends Bootstrap.
*/



/****** Table Of Content *******
*. Functions
*. Variables
*. Mixins
*. Bootstrap Print Styles
*. Bootstrap Reboot
*. Images
*. Code
*. Grid
*. Tables
*. Forms and Custom Forms
*. Input Group
*. Buttons
*. Button Group
*. Transitions
*. Dropdown
*. Navigation and Tabs
*. Navbar
*. Card
*. Breadcrumbs
*. Pagination
*. Badges
*. Jumbotron
*. Alert
*. Progress
*. Media
*. List-group
*. Close
*. Modal
*. Tooltip
*. Popover
*. Carousel
*. Utilities
*. Icons
*. Layout
*. Sidebar
*. Topbar
*. Timeline


/* Bootstrap Functions */
@import "../../node_modules/bootstrap/scss/functions";
@import "functions";

/* Variables */
@import "variables";
@import "../../node_modules/bootstrap/scss/variables";


/* Mixins */
@import "../../node_modules/bootstrap/scss/mixins";
@import "mixins/mixins";

/* Bootstrap Root Styling and Variables */
@import "../../node_modules/bootstrap/scss/root";
@import "root";

/* Bootstrap Print Styling */
@import "../../node_modules/bootstrap/scss/print";

/* Bootstrap Reboot */
@import "../../node_modules/bootstrap/scss/reboot";

/* Typography */
@import "../../node_modules/bootstrap/scss/type";
@import "typography";

/* Images */
@import "../../node_modules/bootstrap/scss/images";

/* Code */
@import "../../node_modules/bootstrap/scss/code";

/* Bootstrap Grid */
@import "../../node_modules/bootstrap/scss/grid";

/* Tables */
@import "../../node_modules/bootstrap/scss/tables";
@import "tables";

/* Forms */
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "forms";


/* Input Group */
@import "../../node_modules/bootstrap/scss/input-group";

/* Buttons */
@import "../../node_modules/bootstrap/scss/buttons";
@import "buttons";

/* Button Group */
@import "../../node_modules/bootstrap/scss/button-group";


/* Transitions */
@import "../../node_modules/bootstrap/scss/transitions";

/* Dropdown */
@import "../../node_modules/bootstrap/scss/dropdown";
@import "dropdown";

/* Navigation and Tabs */
@import "../../node_modules/bootstrap/scss/nav";
@import "nav";

/* Navbar */
@import "../../node_modules/bootstrap/scss/navbar";

/* Cards */
@import "../../node_modules/bootstrap/scss/card";
@import "cards";

/* Breadcrumbs */
@import "../../node_modules/bootstrap/scss/breadcrumb";

/* Pagination */
@import "../../node_modules/bootstrap/scss/pagination";
@import "pagination";

/* Badge */
@import "../../node_modules/bootstrap/scss/badge";

/* Jumbotron */
@import "../../node_modules/bootstrap/scss/jumbotron";

/* Alert */
@import "../../node_modules/bootstrap/scss/alert";

/* Progress */
@import "../../node_modules/bootstrap/scss/progress";
@import "progress";

/* Media */
@import "../../node_modules/bootstrap/scss/media";
@import "media";

/* List Group */
@import "../../node_modules/bootstrap/scss/list-group";
@import "lists";

/* Close Button */
@import "../../node_modules/bootstrap/scss/close";

/* Modal */
@import "../../node_modules/bootstrap/scss/modal";

/* Tooltip */
@import "../../node_modules/bootstrap/scss/tooltip";
.tooltip {
  font-size: 0.85rem !important;
}

/* Popover */
@import "../../node_modules/bootstrap/scss/popover";

/* Carousel */
@import "../../node_modules/bootstrap/scss/carousel";
@import "carousel";

/* Utilites */
@import "../../node_modules/bootstrap/scss/utilities";
@import "utilities";

/* Icons */
@import "icons/icons";

/* Layout */
@import "layout";

/* Sidebar */
@import "sidebar";

/* Topbar */
@import "topbar";

/* Timeline Component */
@import "timeline";

/* Charts */
@import "charts";

/* Calendar */
@import "calendar";

/* Email */
@import "pages/email";
@import "pages/website";

/*
Set the root font size to make the entire UI larger or smaller.
*/
:root {
  font-size: 14px;
  @include media-breakpoint-up(xs) {
    font-size: 14px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-up(md) {
    font-size: 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 15px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}

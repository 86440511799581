.media-box {
  width: 3.15rem;
  height: 3.15rem;
  border-radius: $border-radius;
  line-height: 3.15rem;
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
  &-sm {
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2.75rem;
    font-size: 0.9rem;
  }
  &-xs {
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
    font-size: 0.75rem;
  }
}
.image-with-text {
  position: relative;
  .image-text  {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    padding: 0.7rem;
    background: rgba(0,0,0,0.2);

  }

}
.img-thumb-sq {
  border-radius: 2px !important;
}
.img-thumb-sm {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.img-thumb {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
}
.img-thumb-xs {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
}
.img-thumb-lg {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}
.img-thumb-xl {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
}
.img-thumb-xxl {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}
.img-thumb-xlg {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;

  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.2);
}
.pull-up-lg {

  margin-top: -4rem;
}

.list-group-linked {
  border: 1px solid $gray-100;
  margin: 0;
  padding: 0;
  .list-group-item {
    border-bottom: 1px solid $gray-100;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-bottom: 0;
    padding: 0;

    a {
      padding: 1rem;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        background-color: $gray-100;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }

}

.border-top {
  border-top: 1px solid $separator-color;
}
.border-right {
  border-right: 1px solid $separator-color;
}
.border-bottom {
  border-bottom: 1px solid $separator-color;
}
.border-left {
  border-left: 1px solid $separator-color;
}
.flex-h-grid > * {
  border-right: 1px solid $separator-color;
  flex: 1;
  &:last-child {
    border-right:0;
  }
}
@each $key, $value in $spacers {
  .text-size-#{$key} {
    font-size: $value;
  }

}

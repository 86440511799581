.progress-thin,.progress-sm {
  height: 4px;
  border-radius: 0;
}
.progress-xs {
  height: 3px;
  border-radius: 0;
}
.progress-vertical {
  display: flex;
  flex-direction: column !important;
  justify-content: end !important;
  width: 1rem;
  height: 15rem;
  &.progress-thin {
    width: 5px;
    height: 15rem;

  }
  .progress-bar {
    width: 100%;

  }
}
